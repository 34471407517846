import { Button, Text } from "capsa-ui";
import { SourceNode } from "../types/api";
import { useState } from "react";

type Props = {
  sources?: Record<string, SourceNode>;
  onSourceClick: (source: SourceNode) => void;
  sourceList: string[];
};

export const ChatSourceList = ({
  sources,
  onSourceClick,
  sourceList,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (sourceList.length === 0) {
    return null;
  }

  return (
    <>
      {isExpanded ? (
        <div className="flex flex-col gap-0.5 mt-2">
          <div className="mb-1">
            <Text type="caption" className="text-subtle" weight="bold">
              SOURCES
            </Text>
          </div>
          {sourceList.map((id) => {
            const source = sources?.[id];
            let name = "source";

            if (!source) {
              return null;
            }

            switch (source.source_info.type) {
              case "file":
              case "file_table":
                name = source.source_info.name;
                break;

              case "webpage":
                name = source.source_info.url;
                break;

              case "external_webpage":
                name = source.source_info.url;
                break;

              default:
                break;
            }

            const sourceIndex = sources
              ? Object.keys(sources).indexOf(id) + 1
              : 0;

            return (
              <a
                key={id}
                className="cursor-pointer hover:brightness-90 no-underline"
                onClick={() => onSourceClick(source)}
              >
                <span className="text-label text-primary">
                  [{sourceIndex}]{" "}
                </span>
                <Text className="text-label text-primary">
                  {name}
                  {source.source_info.type === "file" &&
                    ` (Page ${source.source_info.page_number})`}
                </Text>
              </a>
            );
          })}
        </div>
      ) : (
        <Button
          variant="outline"
          iconRight="chevronDown"
          onClick={() => setIsExpanded(true)}
        >
          Show sources
        </Button>
      )}
    </>
  );
};
