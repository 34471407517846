import { baseURL } from "@/api/shared";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { QueryConfig } from "@/lib/reactQuery";
import { User } from "@/types";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";

export interface DomainLogoRead {
  url: string;
}

export const getDomainLogo = async (
  user: User,
  domain: string,
): Promise<DomainLogoRead> => {
  const response = await fetch(`${baseURL}/domain-logo/${domain}`, {
    headers: {
      Authorization: `Bearer ${await user.getIdToken()}`,
    },
  });
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  return { url };
};

type useDomainLogoOptions = {
  domain: string;
  config?: QueryConfig<typeof getDomainLogo>;
};

export const useDomainLogo = ({ domain, config }: useDomainLogoOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: ["domain-logo", domain],
    queryFn: () => getDomainLogo(user, domain),
    enabled: !!user && !!domain,
    retry: defaultRetryFunc,
    ...config,
  });
};
