import { Button, cn, TextArea } from "capsa-ui";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChatMessage } from "./Chat";
import { useCreateThread } from "../api/createThread";
import { useValidatedParams } from "@/utils/router";
import { useSendMessage } from "../api/sendMessage";
import { useThread } from "../api/getThread";
import { ChatThinking } from "./ChatThinking";
import { ChatFailed } from "./ChatFailed";
import { useAssistantStore } from "../store/useAssistantStore";
import { SourceNode } from "../types/api";
import { ChatHistory } from "./ChatHistory";
import { Widget } from "@/types/widgets";
import { useChatPosition } from "../hooks/useChatPosition";
import { useConfigureThread } from "../api/configureThread";
import { usePostHog } from "posthog-js/react";

type Props = {
  widget: Widget;
  onSourceClick: (source: SourceNode) => void;
};

export const Assistant = React.memo(
  ({ widget, onSourceClick }: Props) => {
    const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [input, setInput] = useState("");
    const [focused, setFocused] = useState(false);
    const createThread = useCreateThread({
      orgId,
      dealId,
      widgetId: widget.id,
    });
    const { threadId, setThreadId, resetThreadId } = useAssistantStore();
    const sendMessage = useSendMessage({
      orgId,
      dealId,
      widgetId: widget.id,
      threadId,
    });
    const configureThread = useConfigureThread({
      orgId,
      dealId,
      widgetId: widget.id,
      threadId,
    });
    const thread = useThread({
      orgId,
      dealId,
      widgetId: widget.id,
      threadId,
    });

    const postHog = usePostHog();

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
      try {
        e?.preventDefault();

        if (!threadId) {
          const newThread = await createThread.mutateAsync(undefined);
          setThreadId(newThread.id);
        }

        setTimeout(() => {
          sendMessage.mutateAsync(input);
          setInput("");
        }, 0);
      } catch (error) {
        console.error(error);
      }
    };

    const handleSearchWebToggle = async (
      e?: React.FormEvent<HTMLButtonElement>,
    ) => {
      try {
        e?.preventDefault();

        let searchWebEnabled = true;
        if (!threadId) {
          const newThread = await createThread.mutateAsync(undefined);
          setThreadId(newThread.id);
        } else if (thread.data) {
          searchWebEnabled = !thread.data.state.config.search_web_enabled;
        }

        setTimeout(() => {
          configureThread.mutateAsync({
            search_web_enabled: searchWebEnabled,
          });
        }, 0);
      } catch (error) {
        console.error(error);
      }
    };

    const messages = useMemo(() => {
      const data = thread?.data?.state.messages;
      const order = thread?.data?.state.message_order;

      if (!data || !order) return [];

      return order.map((id) => data[id]).filter(Boolean);
    }, [thread.data]);

    const scrollableRef = useRef<HTMLDivElement>(null);
    const { scrollPadding } = useChatPosition({
      messages,
      scrollableRef,
    });

    const thinking = useMemo(() => {
      return (
        thread?.data?.status === "pending" ||
        thread?.data?.status === "processing"
      );
    }, [thread]);

    useEffect(() => {
      resetThreadId();
    }, [widget.id]);

    useEffect(() => {
      return () => {
        resetThreadId();
      };
    }, []);

    return (
      <div className="flex flex-1 gap-4 flex-col h-0 min-h-0 py-3">
        {threadId && (
          <div
            ref={scrollableRef}
            className="flex flex-col flex-1 overflow-y-auto min-h-0"
          >
            <div className="flex flex-col p-1 gap-3">
              {messages.map((message, index) =>
                message.content_parts.length > 0 ? (
                  <ChatMessage
                    key={message.id}
                    message={message}
                    onSourceClick={onSourceClick}
                    sources={thread.data?.state.source_nodes}
                    lastMessage={
                      messages.findLastIndex((m) => m.role === "user") ===
                      messages.indexOf(message)
                    }
                    lastReply={
                      index === messages.length - 1 &&
                      message.role === "assistant"
                    }
                  />
                ) : null,
              )}
              {thinking && <ChatThinking />}
              {thread.data?.status === "failed" && <ChatFailed />}
              <div
                style={{
                  height: scrollPadding,
                }}
              />
            </div>
          </div>
        )}
        <form
          className="flex flex-col gap-2 px-3 shrink-0"
          onSubmit={handleSubmit}
        >
          <div
            className={cn(
              "flex flex-col gap-0 border rounded-layout overflow-hidden cursor-text bg",
              focused && "border-primary shadow-focus",
            )}
            onClick={() => inputRef.current?.focus()}
          >
            <TextArea
              name="message"
              inputRef={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={"Ask Capsa AI..."}
              className={cn("relative")}
              autoFocus
              inputClassName={cn(
                "resize-none min-h-0 focus:outline-none focus:ring-0 !shadow-none !border-transparent bg-transparent",
              )}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <div className="flex justify-end gap-1.5 p-2">
              {threadId && (
                <Button
                  type="reset"
                  variant="outline"
                  className="mix-blend-multiply"
                  iconLeft="arrowLeft"
                  onClick={() => resetThreadId()}
                >
                  Back
                </Button>
              )}
              <div className="flex-1" />
              {threadId && (
                <Button
                  type="reset"
                  variant="outline"
                  className="mix-blend-multiply"
                  onClick={() => resetThreadId()}
                  iconRight="add"
                >
                  New thread
                </Button>
              )}
              {postHog.isFeatureEnabled("search_web") &&
                threadId &&
                thread.data && (
                  <Button
                    type="button"
                    variant={
                      thread.data.state.config.search_web_enabled
                        ? "default"
                        : "outline"
                    }
                    className="mix-blend-multiply"
                    onClick={handleSearchWebToggle}
                    iconRight="website"
                  />
                )}
              {postHog.isFeatureEnabled("search_web") && !threadId && (
                <Button
                  type="button"
                  variant="outline"
                  className="mix-blend-multiply"
                  onClick={handleSearchWebToggle}
                  iconRight="website"
                />
              )}
              <Button
                iconRight="capsa"
                type="submit"
                disabled={!input.trim() || thinking}
              >
                Ask
              </Button>
            </div>
          </div>
        </form>
        {!threadId && <ChatHistory widgetId={widget.id} />}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.widget.id === nextProps.widget.id,
);

Assistant.displayName = "Assistant";
