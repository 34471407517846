export const transformChatMarkdown = (content: string) => {
  return (
    content
      // handle citations (except for inside table cells)
      .replace(/【([^】]+)】(?!.*\|)/g, `<source id="$1" />`)
      // handle tables
      .replace(/```md\n([^`]+)\n```/g, "$1")
      // handle katex
      .replace(/\\\\\[/g, "$$$$") // Replace '\\[' with '$$'
      .replace(/\\\\\]/g, "$$$$") // Replace '\\]' with '$$'
      .replace(/\\\\\(/g, "$$$$") // Replace '\\(' with '$$'
      .replace(/\\\\\)/g, "$$$$") // Replace '\\)' with '$$'
      .replace(/\\\[/g, "$$$$") // Replace '\[' with '$$'
      .replace(/\\\]/g, "$$$$") // Replace '\]' with '$$'
      .replace(/\\\(/g, "$$$$") // Replace '\(' with '$$'
      .replace(/\\\)/g, "$$$$") // Replace '\)' with '$$';
  );
};

export const extractSourceIdsFromMarkdown = (content: string) => {
  return Array.from(
    new Set(
      content
        .match(/【([^】]+)】/g)
        ?.map((match) => match.replace(/^【|】$/g, "")) || [],
    ),
  );
};
