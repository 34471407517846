import React, { useEffect, useMemo, useState } from "react";
import { Button, EditableHeading, Tabs, Divider, Badge } from "capsa-ui";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { useNavigate } from "react-router-dom";

// Tabs
import TabAliases from "@/features/companies/components/TabAliases";
import TabCompany from "@/features/companies/components/TabCompany";
import TabFiles from "@/features/companies/components/TabFiles";
import TabRegistrySearch from "@/features/companies/components/TabRegistrySearch";
import TabWebsite from "@/features/companies/components/TabWebsite";
import { useCreateDealMutation } from "@/hooks/deals";
import UploadFilesPixel from "@/components/DealsTab/subcomponents/UploadFilesPixel";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";
import { sendEvent } from "@/lib/posthog";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import { useInitSharepoint } from "@/features/sharepoint/hooks/config";
import { useCreateSyncJobMutation } from "@/features/sharepoint/hooks/syncJobs";
import { usePostHog } from "posthog-js/react";
import { PostHog } from "posthog-js";
import { createURL } from "@/utils/urls";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

const getTabSections = (postHog: PostHog) => {
  const tabSections = [];
  tabSections.push("COMPANY");
  if (postHog.isFeatureEnabled("legal_entity_search")) {
    tabSections.push("LEGAL ENTITY");
  }
  tabSections.push("WEBSITE");
  tabSections.push("ALIASES");
  tabSections.push("FILES");
  return tabSections;
};

export const CreateCompany = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const navigate = useNavigate();
  const {
    reset,
    tabIndex,
    setTabIndex,
    dealName,
    setDealName,
    companyName,
    website,
    aliases,
    files,
    legalEntity,
    registryDocuments,
    setDealId,
    dealId,
    companySuggestion,
  } = useCreateCompanyStore();
  const registrySearchStore = useRegistrySearchStore();
  const sharepointStore = useSharepointStore();
  useInitSharepoint();
  const sharepointMutation = useCreateSyncJobMutation();

  const mutation = useCreateDealMutation(orgId as string);
  const [filesUploadCompleted, setFilesUploadCompleted] = useState(false);
  const [creatingDeal, setCreatingDeal] = useState(false);
  const posthog = usePostHog();

  const tabSections = getTabSections(posthog);

  const finalTags: string[] = useMemo(() => {
    const tags: string[] = [];
    if (companyName) tags.push(companyName);
    if (legalEntity) tags.push(legalEntity.name);
    if (registryDocuments.length > 0)
      tags.push(`${registryDocuments.length} Documents`);
    if (website) {
      const websiteTag = createURL(website);
      tags.push(websiteTag?.hostname || website);
    }
    if (aliases.length > 0) tags.push(`${aliases.length} Aliases`);
    if (files.length > 0) tags.push(`${files.length} Files`);
    return tags;
  }, [companyName, website, aliases, files, legalEntity, registryDocuments]);

  const handleCreateDeal = () => {
    setCreatingDeal(true);
    sendEvent("clicked_create_deal", {
      feature: "create_company",
    });
    mutation.mutate(
      {
        name: dealName,
        companyName: companyName,
        companyAliases: aliases.map((alias) => alias.label),
        website: website,
        companyIdInRegistry: legalEntity?.id || "",
        companyLegalName: legalEntity?.name || "",
        registryFiles: registryDocuments.map((doc) => ({
          id: doc.documentId,
          registryIdentifier: doc.registryIdentifier,
        })),
        countryCode: companySuggestion?.countryCode || "",
      },
      {
        onSuccess: (data) => {
          setDealId(data.id);
        },
        onSettled: () => {
          setCreatingDeal(false);
        },
      },
    );
  };

  const handleCancel = () => {
    navigate(paths.orgs.selected.deals.getHref(orgId));
  };

  useEffect(() => {
    return () => {
      registrySearchStore.reset();
      sharepointStore.reset();
      reset();
    };
  }, []);

  useEffect(() => {
    if (dealId) {
      const { sharepointEnabled, selectedFolderIds, selectedFileIds } =
        sharepointStore;
      if (sharepointEnabled) {
        sharepointMutation.mutate({
          externalFolderIds: selectedFolderIds,
          externalFileIds: selectedFileIds,
          externalDealId: dealId,
        });
      }
      if (files.length === 0 || filesUploadCompleted) {
        registrySearchStore.reset();
        reset();
        if (posthog.isFeatureEnabled("dashboard")) {
          navigate(
            paths.orgs.selected.deals.selected.dashboards.current.getHref(
              orgId,
              dealId,
            ),
          );
        } else {
          navigate(
            paths.orgs.selected.deals.selected.files.getHref(orgId, dealId),
          );
        }
      }
    }
  }, [dealId, filesUploadCompleted]);

  return (
    <DashboardLayout
      title={
        <div className="flex gap-4 items-center">
          <EditableHeading value={dealName} onChange={setDealName} />
          {finalTags.length > 0 && (
            <>
              <Divider direction="vertical" className="h-5" />
              <div className="flex gap-1 flex-wrap">
                {finalTags.map((tag) => (
                  <Badge key={tag}>{tag}</Badge>
                ))}
              </div>
            </>
          )}
        </div>
      }
      actions={[
        <Button onClick={handleCancel} variant="outline" key="cancel">
          Cancel
        </Button>,
        <Button
          onClick={handleCreateDeal}
          disabled={!companyName}
          loading={creatingDeal}
          key="create"
        >
          Create Deal
        </Button>,
      ]}
    >
      <div className="flex flex-col pb-24 gap-4">
        <Tabs
          tabs={tabSections.map((section, index) => ({
            label: section,
            disabled: companyName.length === 0 && index !== 0,
          }))}
          selectedTab={tabIndex}
          onSelectTab={setTabIndex}
        >
          {tabSections.map((section) => (
            <div key={section} className="pt-4 flex flex-col gap-6">
              {section === "COMPANY" && <TabCompany />}
              {section === "LEGAL ENTITY" && <TabRegistrySearch />}
              {section === "WEBSITE" && <TabWebsite />}
              {section === "ALIASES" && <TabAliases />}
              {section === "FILES" && (
                <TabFiles
                  onCreateDeal={handleCreateDeal}
                  creatingDeal={creatingDeal}
                />
              )}
            </div>
          ))}
        </Tabs>
      </div>
      {dealId && (
        <UploadFilesPixel
          orgId={orgId || ""}
          dealId={dealId}
          files={files}
          onUploadComplete={() => setFilesUploadCompleted(true)}
        />
      )}
    </DashboardLayout>
  );
};
