import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationConfig } from "@/lib/reactQuery";

export const sendMessage = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
  threadId: string,
  message?: string,
) => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}/assistant-threads/${threadId}/commands/send-message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({ message }),
      },
    ),
    true,
  );
};

type useSendMessageOptions = {
  orgId: string;
  dealId: string;
  widgetId: string;
  threadId?: string;
  config?: MutationConfig<typeof sendMessage>;
};

export const useSendMessage = ({
  orgId,
  dealId,
  widgetId,
  threadId,
  config,
}: useSendMessageOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (message: string) => {
      if (!threadId) {
        throw new Error("Cannot send message: threadId is undefined");
      }
      return sendMessage(user, orgId, dealId, widgetId, threadId, message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "widgets",
          widgetId,
          "assistant-threads",
          threadId,
        ],
      });
    },
    ...config,
  });
};
