import { paths } from "@/config/paths";
import info from "../info";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Button, Text, CapsaLogo, Divider } from "capsa-ui";

export default function SingleTenantOrgInvite() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="flex flex-col gap-6 max-w-[400px] mx-auto bg rounded-layout border p-10">
      <div className="flex gap-4 justify-between items-center">
        <CapsaLogo />
        <Text className="text-center" type="subheading" weight="medium">
          {info.orgId}
        </Text>
      </div>
      <Divider />
      <div className="flex flex-col gap-2 flex-1">
        <Button
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
            })
          }
          fullWidth
          size="lg"
        >
          Sign up
        </Button>
        <div className="flex items-center gap-3 flex-1">
          <Divider className="flex-1" />
          <Text type="label" className="text-faint">
            or
          </Text>
          <Divider className="flex-1" />
        </div>
        <Button
          onClick={() => loginWithRedirect()}
          fullWidth
          size="lg"
          variant="outline"
        >
          Log in
        </Button>
      </div>
      <Divider />
      <Text type="label">
        By singing up or logging in, you accept our{" "}
        <Link to={paths.auth.terms.getHref()} className="text-primary">
          Terms and Conditions
        </Link>{" "}
        and{" "}
        <Link to={paths.auth.privacy.getHref()} className="text-primary">
          Privacy Policy
        </Link>
        .
      </Text>
    </div>
  );
}
