import React, { useMemo } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Icon, IconType } from "../../icons";
import { Spinner } from "../Spinner";
import { cn } from "../../../lib/utils";

export const buttonOuter = cva(
  "focus-outer flex items-center justify-center whitespace-nowrap rounded shrink-0 overflow-hidden",
  {
    variants: {
      variant: {
        default:
          "clickable button-primary-edge border button-primary-border  pb-0.5 active:pb-px",
        outline: "clickable bg-secondary border pb-0.5 active:pb-px",
        ghost: "clickable pressable bg mix-blend-multiply",
        dark: "pressable bg-inverted hover:brightness-125",
        danger:
          "clickable button-danger-edge border button-danger-border pb-0.5 active:pb-px",
      },
      size: {
        sm: "h-7 gap-1 text-label",
        default: "h-8 gap-1.5 text-body",
        lg: "h-10 gap-2 text-subheading",
      },
      loading: {
        true: "pointer-events-none",
      },
      disabled: {
        true: "disabled",
      },
      iconOnly: {
        true: "",
      },
      fullWidth: {
        true: "w-full",
        false: "w-fit-content",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      fullWidth: false,
    },
    compoundVariants: [
      { iconOnly: true, size: "sm", class: "w-7 h-7 min-w-7 min-h-7" },
      { iconOnly: true, size: "default", class: "w-8 h-8 min-w-8 min-h-8" },
      { iconOnly: true, size: "lg", class: "w-10 h-10 min-w-10 min-h-10" },
      { iconOnly: true, fullWidth: true, class: "w-full" },
    ],
  },
);

export const buttonInner = cva(
  "flex items-center justify-center whitespace-nowrap rounded shrink-0 h-full",
  {
    variants: {
      variant: {
        default:
          "button-primary-bg button-primary-text font-bold border-b button-primary-border",
        outline: "bg border-b shadow-none",
        ghost: "bg-transparent bg-opacity-0 shadow-none",
        dark: "bg-white/0 bg-opacity-0 text-inverted font-medium shadow-none",
        danger:
          "button-danger-bg button-danger-text font-bold border-b button-danger-border",
      },
      size: {
        sm: "px-1.5 gap-1.5 text-label",
        default: "px-2 gap-2 text-body",
        lg: "px-3 gap-3 text-subheading",
      },
      iconOnly: {
        true: "w-full",
      },
      fullWidth: {
        true: "w-full",
        false: "w-fit-content",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonVariant = VariantProps<typeof buttonOuter>["variant"];
export type ButtonSize = VariantProps<typeof buttonOuter>["size"];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonOuter> {
  disabled?: boolean;
  loading?: boolean;
  value?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  fullWidth?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant = "default",
      size = "default",
      iconLeft,
      iconRight,
      loading,
      disabled,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const iconSize = useMemo(() => {
      switch (size) {
        case "lg":
          return "default";
        default:
          return "sm";
      }
    }, [size]);

    const spinnerVariant = useMemo(() => {
      switch (variant) {
        case "default":
        case "danger":
        case "dark":
          return "inverted";
        default:
          return "default";
      }
    }, [variant]);

    const iconOnly = useMemo(
      () => !children && (!!iconLeft || !!iconRight),
      [children, iconLeft, iconRight],
    );

    return (
      <button
        className={cn(
          buttonOuter({
            variant,
            size,
            className,
            disabled,
            loading,
            iconOnly,
            fullWidth,
          }),
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        <span
          className={cn(
            "pointer-events-none relative",
            buttonInner({
              variant,
              size,
              iconOnly,
              fullWidth,
            }),
          )}
        >
          {loading && (
            <Spinner
              variant={spinnerVariant}
              size={iconSize}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          )}
          {iconLeft && (
            <Icon
              type={iconLeft}
              size={iconSize}
              className={cn(loading && "opacity-0")}
            />
          )}
          {!iconOnly &&
            children &&
            React.Children.map(children, (child) =>
              React.isValidElement(child) ? (
                React.cloneElement(
                  child as React.ReactElement<{ className?: string }>,
                  {
                    className: cn(
                      child.props.className,
                      loading && "opacity-0",
                    ),
                  },
                )
              ) : (
                <span className={cn(loading && "opacity-0")}>{child}</span>
              ),
            )}
          {iconRight && (
            <Icon
              type={iconRight}
              size={iconSize}
              className={cn(loading && "opacity-0")}
            />
          )}
        </span>
      </button>
    );
  },
);
Button.displayName = "Button";
