import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../../lib/utils";

export const styles = cva(" clickable bg flex items-center h-fit text-left", {
  variants: {
    variant: {
      default: "focus-outer border rounded",
      ghost: "focus-inner",
    },
    size: {
      sm: "gap-1 p-2",
      default: "gap-2 p-3",
    },
  },
  defaultVariants: {
    size: "default",
    variant: "default",
  },
});

export type ClickableSize = VariantProps<typeof styles>["size"];
export type ClickableVariant = VariantProps<typeof styles>["variant"];

export interface ClickableProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof styles> {
  disabled?: boolean;
}

export const Clickable = React.forwardRef<HTMLButtonElement, ClickableProps>(
  (
    {
      children,
      className,
      size = "default",
      variant = "default",
      disabled,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        className={cn(
          styles({ size, variant }),
          disabled && "disabled",
          className,
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    );
  },
);
Clickable.displayName = "Clickable";
