import {
  Button,
  cn,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Text,
} from "capsa-ui";
import { Components } from "react-markdown";
import { SourceNode } from "../types/api";
import React from "react";

type Props = {
  sources?: Record<string, SourceNode>;
  onSourceClick: (source: SourceNode) => void;
};

export const ChatComponents = ({
  sources,
  onSourceClick,
}: Props): Partial<Components> => ({
  // @ts-expect-error - custom component type
  source: ({ id }: { id: string }) => {
    const sourceIndex = sources ? Object.keys(sources).indexOf(id) + 1 : -1;

    if (sourceIndex === -1) {
      return null;
    }

    const source = sources?.[id];

    if (!source) {
      return null;
    }

    return (
      <>
        {" "}
        <a
          className="text-primary cursor-pointer hover:brightness-90 no-underline font-default"
          onClick={() => onSourceClick(source)}
        >
          [{sourceIndex}]
        </a>
      </>
    );
  },
  h1: (props) => {
    return <h1 className="text-h1 font-medium">{props.children}</h1>;
  },
  h2: (props) => {
    return <h2 className="text-h2 font-medium">{props.children}</h2>;
  },
  h3: (props) => {
    return <h3 className="text-h3 font-medium">{props.children}</h3>;
  },
  strong: (props) => {
    return (
      <strong className="text text-body font-medium">{props.children}</strong>
    );
  },
  table: (props) => {
    return (
      <div className="w-full max-w-full min-w-0 overflow-auto shadow-outline rounded-layout mb-4">
        <table
          className={cn(
            "text text-body border-collapse m-0",
            "[&_table]:table-auto",
            "[&_td]:!p-3 [&_td]:!border [&_strong]:!font-default",
            "[&_th]:!px-3 [&_th]:!py-1.5 [&_th]:!border [&_th]:!text-label [&_th]:text-subtle [&_th]:font-medium",
          )}
        >
          {props.children}
        </table>
      </div>
    );
  },
  td: (props) => {
    const getContentFromChildren = (children: React.ReactNode): string => {
      if (typeof children === "string") {
        return children;
      }

      if (typeof children === "object") {
        if (React.isValidElement(children)) {
          return children.props.children?.toString() || "";
        }

        return String(children);
      }

      return "";
    };

    const content = getContentFromChildren(props.children);
    const text = content.replace(/【([^】]+)】/g, "").trim();
    const citations =
      content.match(/【([^】]+)】/g)?.map((c) => c.slice(1, -1)) || [];

    return (
      <td className="border p-2">
        {content ? (
          <HoverCard openDelay={250} closeDelay={100}>
            <HoverCardTrigger asChild>
              <span>{text}</span>
            </HoverCardTrigger>
            {citations.length > 0 && (
              <HoverCardContent
                hideWhenDetached={true}
                className="flex-center gap-0.5 rounded-md border bg-inverted p-0.5"
              >
                {citations.map((citation) => {
                  const sourceIndex = sources
                    ? Object.keys(sources).indexOf(citation) + 1
                    : -1;

                  if (sourceIndex === -1) {
                    return null;
                  }

                  return (
                    <Button
                      variant="dark"
                      size="sm"
                      key={citation}
                      onClick={() => {
                        if (sources?.[citation]) {
                          onSourceClick(sources?.[citation]);
                        }
                      }}
                    >
                      {sourceIndex}
                    </Button>
                  );
                })}
              </HoverCardContent>
            )}
          </HoverCard>
        ) : (
          <Text type="label" className="text-subtle">
            -
          </Text>
        )}
      </td>
    );
  },
});
