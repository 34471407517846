import {
  baseURL,
  PaginatedResponse,
  resolveFetchPaginated,
} from "@/api/shared";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { QueryConfig } from "@/lib/reactQuery";
import { User } from "@/types";
import { assertUserIsAuthenticated } from "@/utils";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Thread } from "../types/api";

export const getThreads = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
  cursor?: string,
  limit?: number,
): Promise<PaginatedResponse<Thread>> => {
  const params = new URLSearchParams();

  if (cursor) {
    params.append("cursor", cursor);
  }

  if (limit) {
    params.append("limit", limit.toString());
  }

  return await resolveFetchPaginated(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}/assistant-threads?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

type useThreadsOptions = {
  orgId: string;
  dealId: string;
  widgetId?: string;
  config?: QueryConfig<typeof getThreads>;
};

export const useThreads = ({
  orgId,
  dealId,
  widgetId,
  config,
}: useThreadsOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useInfiniteQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "widgets",
      widgetId,
      "assistant-threads",
    ],
    queryFn: ({ pageParam = "" }) => {
      if (!widgetId) {
        return null;
      }
      return getThreads(user, orgId, dealId, widgetId, pageParam, 5);
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage) {
        return null;
      }
      return lastPage.nextCursor.value;
    },
    enabled: !!user && !!widgetId,
    retry: defaultRetryFunc,
    ...config,
  });
};
