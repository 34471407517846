import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  EdgeTypes,
  NodeMouseHandler,
  ReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { EntityNode as EntityNodeComponent } from "@/features/registrySearch/components/reactFlow/CustomNode";
import { Spinner, Text } from "capsa-ui";
import useEntityTree from "@/features/registrySearch/hooks/useEntityTree";
import { CustomEdge as CustomEdgeComponent } from "@/features/registrySearch/components/reactFlow/CustomEdge";
import { useCompanyHeirarchy } from "@/features/registrySearch/hooks/useCompanyHeirarchy";
import { Company } from "@/features/companies/types/company";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";
import { CustomControls } from "@/features/registrySearch/components/reactFlow/CustomControls";

const Diagram = () => {
  const {
    setLegalEntity,
    selectedCompanySuggestion,
    setEntityNodes,
    setEntityEdges,
    resetLegalEntity,
    setLoading,
  } = useRegistrySearchStore();
  const { nodes, edges, loading, onNodesChange, onEdgesChange, centerNode } =
    useEntityTree();
  const companyHeirarchy = useCompanyHeirarchy(selectedCompanySuggestion);
  const nodeTypes = useMemo(() => ({ customNode: EntityNodeComponent }), []);
  const edgeTypes = useMemo(() => ({ customEdge: CustomEdgeComponent }), []);

  useEffect(() => {
    if (companyHeirarchy.data) {
      setEntityNodes(companyHeirarchy.data.companies);
      setEntityEdges(companyHeirarchy.data.relations);
    } else {
      setEntityNodes([]);
      setEntityEdges({});
      resetLegalEntity();
    }
  }, [companyHeirarchy.data]);

  useEffect(() => {
    setLoading(companyHeirarchy.isLoading);
  }, [companyHeirarchy.isLoading]);

  const onNodeClick: NodeMouseHandler = useCallback(
    (_, node) => {
      setLegalEntity(node.data as unknown as Company);
    },
    [centerNode],
  );

  const StateWrapper = ({ children }: { children: ReactNode }) => (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
        {children}
      </div>
    </div>
  );

  if (companyHeirarchy.isLoading) {
    return (
      <StateWrapper>
        <Spinner size="lg" />
        <Text color="subtle" type="label">
          Finding legal entities...
        </Text>
      </StateWrapper>
    );
  }

  if (companyHeirarchy.isError || nodes.length === 0) {
    return (
      <StateWrapper>
        <Text color="subtle" type="label">
          No legal entities found. Choose another company.
        </Text>
      </StateWrapper>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes as EdgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        proOptions={{ hideAttribution: true }}
        className={loading ? "opacity-0" : ""}
        onNodeClick={onNodeClick}
      >
        <CustomControls />
      </ReactFlow>
    </div>
  );
};

export const EntitiesFlowDiagram = () => (
  <ReactFlowProvider>
    <Diagram />
  </ReactFlowProvider>
);
