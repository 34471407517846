import { DealRead } from "@/api/deals";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";

const initialState = {
  selectedCompany: undefined as DealRead | undefined,
  dashboardNavOpen: true,
};

export const useGlobalStore = create(
  devtools(
    persist(
      subscribeWithSelector(
        combine(initialState, (set) => ({
          setSelectedCompany: (selectedCompany: DealRead) =>
            set({ selectedCompany }),
          resetSelectedCompany: () => set({ selectedCompany: undefined }),
          openDashboardNav: () => set({ dashboardNavOpen: true }),
          closeDashboardNav: () => set({ dashboardNavOpen: false }),
          setDashboardNavOpen: (open: boolean) =>
            set({ dashboardNavOpen: open }),
        })),
      ),
      {
        name: "GlobalStore",
      },
    ),
  ),
);
