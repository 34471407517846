import { withVariants } from "../../../lib/withVariants";

const styles = withVariants({
  base: "outline-none font-bold text relative",
  variants: {
    type: {
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
    },
    isEditing: {
      true: "bg-primary",
    },
  },
});

export const iconStyle = withVariants({
  base: "bg border flex items-center justify-center w-6 h-6 rounded cursor-pointer",
  variants: {
    isEditing: {
      true: "bg-primary opacity-25",
    },
  },
});

export default styles;
