import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThreadConfig } from "../types/api";

export const configureThread = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
  threadId: string,
  threadConfig: ThreadConfig,
) => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}/assistant-threads/${threadId}/commands/configure-thread`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({ config: threadConfig }),
      },
    ),
    true,
  );
};

type useConfigureThreadOptions = {
  orgId: string;
  dealId: string;
  widgetId: string;
  threadId?: string;
};

export const useConfigureThread = ({
  orgId,
  dealId,
  widgetId,
  threadId,
}: useConfigureThreadOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (threadConfig: ThreadConfig) => {
      if (!threadId) {
        throw new Error("Cannot configure thread: threadId is undefined");
      }
      return configureThread(
        user,
        orgId,
        dealId,
        widgetId,
        threadId,
        threadConfig,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "widgets",
          widgetId,
          "assistant-threads",
          threadId,
        ],
      });
    },
  });
};
