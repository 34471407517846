import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../..";

const badgeVariants = cva(
  "inline-flex items-center rounded-pill border px-2.5 py-0.5 text-label font-medium transition-colors",
  {
    variants: {
      variant: {
        default: "bg-primary text border-transparent",
        outline: "bg text-subtle",
        danger: "border-transparent bg-danger-accent text-inverted",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export type BadgeVariant = VariantProps<typeof badgeVariants>["variant"];

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
