import { Topic } from "@/types/widgets";
import {
  Button,
  cn,
  TextArea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  WidgetEntry,
  WidgetRenderProps,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import {
  bulletTransformer,
  updateBulletPoints,
  getCitationsForText,
  removeCitations,
  extractCitations,
  sortCitations,
} from "../../utils/bullets";
import { useValidatedParams } from "@/utils/router";
import { useUpdateWidget } from "../../api/updateWidget";

interface Props extends WidgetRenderProps {
  content: Topic[];
  widgetId: string;
}

export const WidgetContentBulletPoints = ({
  content,
  onSource,
  setClipboard,
  widgetId,
  refreshing,
}: Props) => {
  const entries = useMemo(() => bulletTransformer(content), [content]);

  useEffect(() => {
    if (!setClipboard) return;

    const text = entries
      .map((topic) => topic.entry?.options?.[0]?.content || "")
      .join("\n");

    setClipboard(
      new ClipboardItem({
        "text/plain": new Blob([text], { type: "text/plain" }),
      }),
    );
  }, [content]);

  return (
    <ul className="flex flex-col marker:text-faint">
      {entries.map(({ entry, topics }) => {
        const allCitations = entry.options[0]?.citations || [];

        // Check if any topic or point has citations
        const hasAnyCitations = topics.some(
          ({ topic, points }) =>
            extractCitations(topic).length > 0 ||
            points.some((point) => extractCitations(point).length > 0),
        );
        return (
          <>
            {topics.map(({ topic, points }, index) => {
              const topicCitations = hasAnyCitations
                ? Array.from(
                    new Set([
                      ...getCitationsForText(topic, allCitations),
                      ...points.flatMap((point) =>
                        getCitationsForText(point, allCitations),
                      ),
                    ]),
                  )
                : allCitations;
              const cleanTopic = removeCitations(topic);
              return (
                <>
                  <WidgetEntry
                    key={`${entry.identifiers.id}-${topic}`}
                    index={index}
                    citations={sortCitations(topicCitations)}
                    onSourceClick={onSource}
                    refreshing={entry.refreshing}
                    widgetRefreshing={refreshing}
                    className={cn(points.length > 0 && "font-medium")}
                    editablePopover={({ onClose }) => (
                      <WidgetContentBulletPointEditor
                        onClose={onClose}
                        point={cleanTopic}
                        level={0}
                        widgetId={widgetId}
                        data={content}
                        sectionIndex={index}
                        pointIndex={0}
                      />
                    )}
                    editablePopoverProps={{
                      sideOffset: 0,
                      collisionBoundary:
                        document.getElementById("dashboard-grid"),
                    }}
                  >
                    <li className="flex gap-2">
                      <span className="text-faint">•</span>
                      <span>{cleanTopic}</span>
                    </li>
                  </WidgetEntry>
                  {points.length > 0 && (
                    <ul
                      key={entry.identifiers.id}
                      className="flex flex-col mb-2"
                    >
                      {points.map((point, pointIndex) => {
                        const pointCitations = hasAnyCitations
                          ? getCitationsForText(point, allCitations)
                          : allCitations;
                        const cleanPoint = removeCitations(point);
                        return (
                          <WidgetEntry
                            key={`${entry.identifiers.id}-${point}`}
                            index={index}
                            citations={sortCitations(pointCitations)}
                            onSourceClick={onSource}
                            refreshing={entry.refreshing}
                            widgetRefreshing={refreshing}
                            editablePopover={({ onClose }) => (
                              <WidgetContentBulletPointEditor
                                onClose={onClose}
                                point={cleanPoint}
                                level={1}
                                widgetId={widgetId}
                                data={content}
                                sectionIndex={index}
                                pointIndex={pointIndex}
                              />
                            )}
                            editablePopoverProps={{
                              sideOffset: 0,
                              collisionBoundary:
                                document.getElementById("dashboard-grid"),
                            }}
                          >
                            <li className="flex gap-2 pl-4">
                              <span className="text-faint">•</span>
                              <span>{cleanPoint}</span>
                            </li>
                          </WidgetEntry>
                        );
                      })}
                    </ul>
                  )}
                </>
              );
            })}
          </>
        );
      })}
    </ul>
  );
};

const WidgetContentBulletPointEditor = ({
  onClose,
  point,
  level,
  widgetId,
  data,
  sectionIndex,
  pointIndex,
}: {
  onClose: () => void;
  point: string;
  level: number;
  widgetId: string;
  data: Topic[];
  sectionIndex: number;
  pointIndex: number;
}) => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { mutateAsync } = useUpdateWidget({ orgId, dealId, widgetId });
  const [value, setValue] = useState(point);
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    setUpdating(false);
    setRemoving(false);
  }, [point]);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement> | null,
    onClose: () => void,
    remove?: boolean,
  ) => {
    try {
      e?.preventDefault();

      const content = data[0];

      const entry = content.entry;
      const option = entry?.options[0];

      if (!option) throw new Error("Option not found");

      if (remove) {
        setUpdating(false);
        setRemoving(true);
      } else {
        setUpdating(true);
        setRemoving(false);
      }

      const newValue = remove ? "" : value;

      if (newValue === point) return;

      await mutateAsync({
        content: updateBulletPoints(
          data,
          sectionIndex,
          pointIndex,
          newValue,
          level,
          remove,
        ),
      });

      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
      setRemoving(false);
    }
  };

  return (
    <form
      onSubmit={(e) => onSubmit(e, onClose)}
      className="relative flex flex-col gap-1.5"
    >
      <TextArea
        id="editable-value"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        className="relative w-full"
        inputClassName="resize-none"
        autoSize
        autoSizePadding={44}
        minHeight={0}
        disabled={updating}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (value === point) return;
            onSubmit(null, onClose);
          }
        }}
        label="Point"
      />
      <div className="absolute bottom-0 w-full flex justify-end gap-1 p-3 z-[1] pointer-events-none">
        <Button
          variant="outline"
          onClick={onClose}
          disabled={updating || removing}
          type="reset"
          className="pointer-events-auto"
        >
          Cancel
        </Button>
        <div className="flex-1" />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              onClick={() => {
                onSubmit(null, onClose, true);
              }}
              disabled={updating}
              type="reset"
              loading={removing}
              iconLeft="delete"
              className="pointer-events-auto"
            />
          </TooltipTrigger>
          <TooltipContent>Remove bullet point</TooltipContent>
        </Tooltip>
        <Button
          type="submit"
          loading={updating}
          disabled={point === value || removing}
          className="pointer-events-auto"
        >
          Update
        </Button>
      </div>
    </form>
  );
};

export const WidgetContentBulletPointsToMarkdown = (content: Topic[]) => {
  const entries = bulletTransformer(content);
  if (entries.length === 0) return "";
  return entries
    .map(({ topics }) => {
      return topics
        .map(({ topic, points }) => {
          const cleanTopic = removeCitations(topic);
          return `* ${points.length > 0 ? `**${cleanTopic}**` : cleanTopic}${
            points.length > 0
              ? `\n${points.map((point) => `  * ${removeCitations(point)}`).join("\n")}`
              : ""
          }`;
        })
        .join("\n");
    })
    .join("\n");
};
