import { Button, Divider, Text, TextField } from "capsa-ui";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { sendEvent } from "@/lib/posthog";
import React, { useEffect, useState } from "react";

const TabAliases = () => {
  const { nextTab, previousTab, aliases, setAliases, removeAlias } =
    useCreateCompanyStore();
  const [input, setInput] = useState("");

  useEffect(() => {
    sendEvent("viewed_aliases_tab", {
      feature: "create_company",
    });
  }, []);

  const handleAddAlias = (alias: string) => {
    const existingIndex = aliases.findIndex((a) => a.label === alias);
    if (existingIndex >= 0) {
      const newAliases = [...aliases];
      newAliases[existingIndex] = {
        id: alias,
        label: alias,
        preventRemoval: false,
      };
      setAliases(newAliases);
    } else {
      setAliases([
        ...aliases,
        { id: alias, label: alias, preventRemoval: false },
      ]);
    }
  };

  const handleRemoveAlias = (alias: string) => {
    removeAlias(alias);
  };

  const handleSkip = () => {
    const newAliases = aliases.filter((alias) => alias.preventRemoval === true);
    setAliases(newAliases);
    nextTab();
  };

  const handleNext = () => {
    if (input.length > 0) {
      handleAddAlias(input);
    }
    nextTab();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddAlias(input);
      setInput("");
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex gap-1.5 items-end relative">
          <TextField
            description="Press enter to add"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            tooltip="Add any additional aliases that the company is referred to by."
            inputClassName="pr-20"
            autoFocus
            label="Add alias"
          />
          <Button
            variant="outline"
            iconRight="add"
            onClick={handleNext}
            className="absolute right-1 bottom-1"
          >
            Add
          </Button>
        </div>
        {aliases.length > 0 && (
          <div className="flex flex-col gap-1.5">
            <Text type="label" color="subtle" weight="bold">
              {`Aliases (${aliases.length})`}
            </Text>
            <div className="border rounded-layout h-fit overflow-scroll max-h-[200px]">
              {aliases.map((alias, index) => (
                <React.Fragment key={alias.id}>
                  <div className="px-3 py-2 flex items-center gap-3 rounded w-full">
                    <Text className="flex-1">{alias.label}</Text>
                    <Button
                      variant="ghost"
                      iconRight="delete"
                      onClick={() => handleRemoveAlias(alias.id)}
                    />
                  </div>
                  {index < aliases.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-1.5">
        <Button variant="outline" iconLeft="chevronLeft" onClick={previousTab}>
          Back
        </Button>
        <div className="flex-1" />
        <Button variant="outline" onClick={handleSkip}>
          Skip
        </Button>
        <Button iconRight="chevronRight" onClick={handleNext}>
          Next
        </Button>
      </div>
    </>
  );
};

export default TabAliases;
