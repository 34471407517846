import { User } from "../../../types";
import { baseURL, mapToSnakeCase, resolveFetch } from "../../../api/shared";
import { WidgetGenerationJob } from "../types/api";
import { QueryConfig } from "@/lib/reactQuery";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface WidgetGenerationJobCreate {
  widgetId: string;
}

export const refreshWidgetGenerationJob = async (
  user: User,
  orgId: string,
  dealId: string,
  data: WidgetGenerationJobCreate,
): Promise<WidgetGenerationJob> => {
  const body = mapToSnakeCase<typeof data>(data);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/widget-generation-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(body),
    }),
    true,
  );
};

type useRefreshWidgetGenerationJobOptions = {
  orgId: string;
  dealId: string;
  config?: QueryConfig<typeof refreshWidgetGenerationJob>;
};

export const useRefreshWidgetGenerationJob = ({
  orgId,
  dealId,
  config,
}: useRefreshWidgetGenerationJobOptions) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: WidgetGenerationJobCreate) =>
      refreshWidgetGenerationJob(user, orgId, dealId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "widget-generation-jobs"],
      });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "widgets"],
        refetchType: "active",
        exact: false,
      });
    },
    ...config,
  });
};
