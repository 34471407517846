import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { Dashboard } from "../types/api";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { QueryConfig } from "@/lib/reactQuery";

export const getDashboard = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<Dashboard> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/dashboards/current`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

type useDashboardOptions = {
  orgId: string;
  dealId: string;
  config?: QueryConfig<typeof getDashboard>;
};

export const useDashboard = ({
  orgId,
  dealId,
  config,
}: useDashboardOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "dashboards", "current"],
    queryFn: () => getDashboard(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    ...config,
  });
};
