import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useQueries } from "@tanstack/react-query";
import { QueryConfig } from "@/lib/reactQuery";
import { getWidget, widgetQuery } from "./getWidget";

type useWidgetsOptions = {
  orgId: string;
  dealId: string;
  widgetIds: string[];
  config?: QueryConfig<typeof getWidget>;
};

export const useWidgets = ({
  orgId,
  dealId,
  widgetIds,
  config,
}: useWidgetsOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQueries({
    queries: widgetIds.map((widgetId) =>
      widgetQuery({ user, orgId, dealId, widgetId, config }),
    ),
  });
};
