import { Toaster as Sonner, toast as sonnerToast } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

export const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      closeButton={props.closeButton ?? true}
      toastOptions={{
        classNames: {
          toast:
            "group toast bg-inverted text-inverted shadow-popover text-body !border",
          title: "!text-inverted !font-medium",
          description: "!text-inverted opacity-75 !font-normal",
          actionButton:
            "!bg-primary-accent !text-inverted !rounded !text-body !px-3 !py-4 !font-bold",
          cancelButton:
            "!bg !text !rounded !text-body !px-3 !py-4 !font-medium !text-subtle",
          closeButton: "!bg-inverted !text-inverted !border",
        },
      }}
      {...props}
    />
  );
};

export const toast = sonnerToast;
