import { withVariants } from "../../../lib/withVariants";

const styles = withVariants({
  base: "",
  variants: {
    type: {
      subheading: "text-subheading",
      body: "text-body",
      label: "text-label",
      caption: "text-caption",
    },
    truncated: {
      true: "whitespace-nowrap overflow-hidden text-ellipsis",
    },
    weight: {
      default: "font-default",
      medium: "font-medium",
      bold: "font-bold",
    },
    color: {
      default: "text",
      subtle: "text-subtle",
      faint: "text-faint",
      primary: "text-primary",
      danger: "text-danger",
    },
    style: {
      default: "",
      underline: "underline",
    },
  },
});

export default styles;
