import * as ResizablePrimitive from "react-resizable-panels";

import { cn } from "../../../lib/utils";
import { Icon } from "../../icons";

const ResizablePanelGroup = ({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) => (
  <ResizablePrimitive.PanelGroup
    className={cn(
      "flex h-full w-full data-[panel-group-direction=vertical]:flex-col",
      className,
    )}
    {...props}
  />
);

const ResizablePanel = ResizablePrimitive.Panel;

const ResizableHandle = ({
  withHandle,
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
}) => (
  <ResizablePrimitive.PanelResizeHandle
    className={cn(
      "group relative flex w-px items-center justify-center bg-[var(--palette-neutral-300)] after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:bg-primary-accent data-[panel-group-direction=vertical]:h-px data-[panel-group-direction=vertical]:w-full data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90",
      "data-[resize-handle-state=hover]:bg-primary-accent data-[resize-handle-state=drag]:bg-primary-accent",
      "data-[resize-handle-state=hover]:shadow-focus data-[resize-handle-state=drag]:shadow-focus transition-all duration-100",
      className,
    )}
    {...props}
  >
    {withHandle && (
      <div
        className={cn(
          "z-10 h-5 w-3.5 flex-center rounded-badge border bg text-faint",
          "group-data-[resize-handle-state=hover]:border-primary",
          "group-data-[resize-handle-state=drag]:bg-primary-accent group-data-[resize-handle-state=drag]:border-primary group-data-[resize-handle-state=drag]:text-inverted",
        )}
      >
        <Icon type="dragVertical" size="sm" />
      </div>
    )}
  </ResizablePrimitive.PanelResizeHandle>
);

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };
