import { useValidatedParams } from "@/utils/router";
import { useState } from "react";
import { useEffect } from "react";
import FileViewer, { FileViewerParams } from "@/components/FileViewer";
import { useDashboardStore } from "../../store/useDashboardStore";
import { Button, ResizableHandle, Text } from "capsa-ui";
import { ResizablePanel } from "capsa-ui";
import { useGlobalStore } from "@/store";

export default function DashboardSourceViewer() {
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });
  const { source, closeSourceViewer, sourceViewerOpen } = useDashboardStore();
  const { closeDashboardNav } = useGlobalStore();

  useEffect(() => {
    if (source) {
      if (source.searchResult.type === "file") {
        setFileViewerParams({
          fileId: source.searchResult.fileId,
          pageNumber: source.searchResult.pageNumber,
          websiteId: null,
          webpageId: null,
          waiting: true,
        });
      } else {
        setFileViewerParams({
          fileId: null,
          pageNumber: null,
          websiteId: source.searchResult.websiteId,
          webpageId: source.searchResult.webpageId,
          waiting: true,
        });
      }
    } else {
      setFileViewerParams({
        fileId: null,
        pageNumber: null,
        websiteId: null,
        webpageId: null,
        waiting: true,
      });
    }
  }, [source]);

  useEffect(() => {
    if (source) {
      closeDashboardNav();
    }
  }, [source]);

  if (!sourceViewerOpen) {
    return null;
  }

  return (
    <>
      <ResizableHandle />
      <ResizablePanel minSize={20} id="source-viewer" order={2}>
        <div className="flex flex-1 flex-col h-full overflow-auto bg-secondary">
          <div className="flex gap-1 h-11 items-center border-b p-1 pl-3">
            <Text type="label" weight="medium" className="flex-1">
              {source?.searchResult.type === "file"
                ? source?.searchResult.fileName
                : source?.searchResult.url}
            </Text>
            <Button
              iconLeft="cancel"
              variant="ghost"
              onClick={closeSourceViewer}
            />
          </div>
          <div className="flex-1 p-3 min-h-0">
            <FileViewer
              orgId={orgId}
              dealId={dealId}
              {...fileViewerParams}
              setFileViewerParams={setFileViewerParams}
              loadDelay={400}
            />
          </div>
        </div>
      </ResizablePanel>
    </>
  );
}
