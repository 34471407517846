import { withVariants } from "../../../lib/withVariants";

const styles = withVariants({
  base: "text",
  variants: {
    type: {
      hero: "text-hero font-bold",
      h1: "text-h1 font-bold",
      h2: "text-h2 font-bold",
      h3: "text-h3 font-bold",
    },
    truncated: {
      true: "whitespace-nowrap overflow-hidden text-ellipsis",
    },
  },
});

export default styles;
