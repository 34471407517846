import { useAuth0 } from "@auth0/auth0-react";
import Intercom, {
  shutdown as shutdown_intercom,
} from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserOrgPermissionsQuery } from "../../hooks";
import info from "../../info";
import { getUserNavigation } from "./common";
import { SidebarProvider } from "capsa-ui";
import DashboardSidebar from "./DashboardSidebar";
import { paths } from "@/config/paths";
import { GlobalLoader } from "./GlobalLoader";
import { useValidatedParams } from "@/utils/router";
import DashboardHeader from "./DashboardHeader";
import { useGlobalStore } from "@/store";
import { TrialEnded } from "./TrialEnded";
import { usePostHog } from "posthog-js/react";

function useUserNavigation() {
  const { logout } = useAuth0();
  return getUserNavigation(() =>
    logout({
      logoutParams: {
        returnTo: `${info.appURL}${paths.auth.login.getHref()}`,
      },
    }),
  );
}

export default function OrgDashboardLayout() {
  const { orgId } = useValidatedParams(["orgId"]);
  const userNavigation = useUserNavigation();
  const permissionQuery = useUserOrgPermissionsQuery(orgId);
  const { user } = useAuth0();
  const { setDashboardNavOpen, dashboardNavOpen } = useGlobalStore();
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      const hmac = user["https://api.capsa.ai/intercom_hmac"];
      Intercom({
        app_id: info.intercomAppId,
        user_id: user.sub,
        name: user.name,
        email: user.email,
        user_hash: hmac,
        hide_default_launcher: true,
      });
    } else {
      shutdown_intercom();
    }
  }, [user, info.orgId]);

  if (permissionQuery.isLoading) {
    return <GlobalLoader />;
  }

  if (permissionQuery.isError || permissionQuery.data.size === 0) {
    return <Navigate to={paths.users.orgs.getHref()} />;
  }

  return (
    <div className="flex flex-row min-h-screen">
      {posthog?.isFeatureEnabled("trial_ended") && <TrialEnded />}
      <SidebarProvider
        open={dashboardNavOpen}
        onOpenChange={setDashboardNavOpen}
      >
        <DashboardSidebar userNavigation={userNavigation} />
        <main className="flex flex-col flex-1 min-w-0 relative min-h-svh">
          <DashboardHeader />
          <Outlet />
        </main>
      </SidebarProvider>
    </div>
  );
}
