import { Icon } from "capsa-ui";
import { motion } from "framer-motion";

export const ChatThinking = () => {
  return (
    <div
      data-last-reply={true}
      className="flex gap-2.5 animate-in fade-in-0 slide-in-from-bottom-1 duration-500 items-center p-2"
    >
      <div className="w-5 h-5 min-w-5 min-h-5 rounded bg-primary-accent flex-center">
        <Icon type="capsa" className="text-inverted" size="xs" />
      </div>
      <div className="flex gap-1">
        {[0, 1, 2].map((index) => (
          <motion.div
            key={index}
            className="w-1.5 h-1.5 bg-primary-accent rounded-full"
            animate={{
              y: ["0%", "-100%", "0%"],
              opacity: [1, 0.5, 1],
            }}
            transition={{
              duration: 1.2,
              repeat: Infinity,
              ease: "easeInOut",
              delay: index * 0.3,
            }}
          />
        ))}
      </div>
    </div>
  );
};
