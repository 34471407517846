import { withVariants } from "../../../lib/withVariants";

export const tabsStyles = withVariants({
  base: "w-full !shadow-none",
});

export const tabListStyles = withVariants({
  base: "flex border-b text-subtle gap-4",
});

export const tabStyles = withVariants({
  base: "clickable pressable focus-tight bg py-1.5 cursor-pointer outline-none -mb-px font-bold text-label border-b",
  variants: {
    isSelected: {
      true: "text-primary border-b border-primary",
    },
    isDisabled: {
      true: "disabled",
    },
  },
});
