import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const merge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        "text-h1",
        "text-h2",
        "text-h3",
        "text-subheading",
        "text-body",
        "text-label",
        "text-caption",
      ],
      "text-color": [
        "text",
        "text-inherit",
        "text-current",
        "text-transparent",
        "text-subtle",
        "text-faint",
        "text-inverted",
        "text-primary",
        "text-success",
        "text-warning",
        "text-danger",
        "text-brand-text",
        "text-brand-mark",

        // components
        "text-component-button-primary-text",
        "text-component-button-danger-text",
      ],
      "bg-color": [
        "bg",
        "bg-inherit",
        "bg-current",
        "bg-transparent",
        "bg-secondary",
        "bg-tertiary",
        "bg-inverted",
        "bg-overlay",
        "bg-primary",
        "bg-primary-highlight",
        "bg-primary-accent",
        "bg-success",
        "bg-success-accent",
        "bg-warning",
        "bg-warning-accent",
        "bg-danger",
        "bg-danger-accent",

        // components
        "bg-component-button-primary-bg",
        "bg-component-button-primary-edge",
        "bg-component-button-danger-bg",
        "bg-component-button-danger-edge",
      ],
    },
    conflictingClassGroups: {
      "font-size": ["font-size"],
      "text-color": ["text-color"],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return merge(clsx(inputs));
}
