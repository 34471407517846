import { Avatar, cn, Heading, ProgressBar, Text } from "capsa-ui";
import { Button } from "capsa-ui";
import { useGlobalStore } from "@/store";
import { useValidatedParams } from "@/utils/router";
import { useWebsitesQuery } from "@/hooks/websites";
import { getCompanyLogoFromWebsites } from "@/utils/image";
import { useDashboardStore } from "../../store/useDashboardStore";
import { useMemo } from "react";
import { Widget } from "@/types/widgets";

type Props = {
  companyName: string;
  companyDescription?: string;
  refreshing: boolean;
  onRefresh: () => void;
  noData: boolean;
  widgets: Widget[];
};

export const DashboardTitle = ({
  companyName,
  companyDescription,
  refreshing,
  onRefresh,
  noData,
  widgets,
}: Props) => {
  const { orgId } = useValidatedParams(["dealId", "orgId"]);
  const { selectedCompany } = useGlobalStore();
  const { setExportDialogOpen, setContextWindowState, setFocusedWidget } =
    useDashboardStore();
  const websites = useWebsitesQuery(orgId, selectedCompany?.id || "");

  const logoUrl = useMemo(
    () => getCompanyLogoFromWebsites(websites.data || []),
    [websites.data],
  );

  const openContextWindow = () => {
    setContextWindowState("assistant");
    setFocusedWidget(widgets[0].id);
  };

  return (
    <header className={cn("flex items-end pb-3 gap-12 relative")}>
      <div className="flex items-start gap-2.5 flex-1 h-full">
        {logoUrl && (
          <div className="flex-center p-1.5 rounded-layout border shrink-0">
            <Avatar src={logoUrl} size="hero" />
          </div>
        )}
        <div className="flex flex-col gap-0.5 flex-1 min-h-full justify-center">
          <Heading>{companyName}</Heading>
          {companyDescription && (
            <div>
              <Text type="label" color="subtle">
                {companyDescription}
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-1 flex-wrap max-w-[50%] justify-end">
        <Button
          key="refresh"
          variant="outline"
          iconLeft="refresh"
          loading={refreshing}
          onClick={onRefresh}
        >
          Refresh
        </Button>
        {!noData && (
          <Button
            key="export"
            iconLeft="export"
            variant="outline"
            onClick={() => setExportDialogOpen(true)}
          >
            Export
          </Button>
        )}
        <Button key="assistant" iconLeft="capsa" onClick={openContextWindow}>
          Assistant
        </Button>
      </div>
      {refreshing && (
        <ProgressBar
          value={null}
          className="absolute bottom-[-1px] left-0 right-0 h-0.5"
        />
      )}
    </header>
  );
};
