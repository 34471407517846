import { useEffect, useState } from "react";
import { ThreadMessage } from "../types/api";

type Props = {
  messages: ThreadMessage[];
  scrollableRef: React.RefObject<HTMLDivElement>;
};

export const useChatPosition = ({ messages, scrollableRef }: Props) => {
  const [scrollPadding, setScrollPadding] = useState(0);

  useEffect(() => {
    if (scrollableRef.current) {
      const lastMessage = document.querySelector('[data-last-message="true"]');
      const lastReply = document.querySelector('[data-last-reply="true"]');

      if (lastMessage) {
        const containerHeight =
          scrollableRef.current.getBoundingClientRect().height;
        const messageHeight = lastMessage.getBoundingClientRect().height;

        let replyHeight = 0;
        if (lastReply) {
          replyHeight = lastReply.getBoundingClientRect().height;
        }

        const spaceNeeded = Math.max(
          0,
          containerHeight - messageHeight - replyHeight - 32,
        );

        console.log(spaceNeeded);

        setScrollPadding(spaceNeeded);

        setTimeout(() => {
          lastMessage.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, [messages]);

  return {
    scrollPadding,
  };
};
