import { cn, ResizablePanel, ResizablePanelGroup } from "capsa-ui";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useGlobalStore } from "@/store";
import { useDealQuery } from "@/hooks/deals";
import { useValidatedParams } from "@/utils/router";
import { useWebsitesQuery } from "@/hooks/websites";
import { Topic } from "@/types/widgets";
import { EmptyDashboard } from "../components/dashboard/EmptyDashboard";
import { WidgetContent } from "../components/widget/WidgetContent";
import { getCompanyLogoDomain } from "@/utils/image";
import { DashboardExport } from "../components/dashboard/DashboardExport";
import { useDashboardStore } from "../store/useDashboardStore";
import { useDashboard } from "../api/getDashboard";
import { useDomainLogo } from "../api/getDomainLogo";
import { useWidgets } from "../api/getWidgets";
import { useRefreshWidgetGenerationJob } from "../api/refreshWidgetGenerationJob";
import { useWidgetGenerationJobs } from "../api/getWidgetGenerationJobs";
import DashboardSourceViewer from "../components/dashboard/DashboardSourceViewer";
import { ContextWindow } from "../components/contextWindow/ContextWindow";
import { DashboardTitle } from "../components/dashboard/DashboardTitle";
import { removeCitations } from "../utils/bullets";

export const DashboardView = () => {
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const dashboardQuery = useDashboard({ orgId, dealId });
  const dealQuery = useDealQuery(orgId, dealId);
  const { setSelectedCompany, selectedCompany } = useGlobalStore();
  const {
    source,
    focusedWidget,
    contextWindowState,
    sourceViewerOpen,
    resetFocusedWidget,
    setContextWindowState,
    closeSourceViewer,
  } = useDashboardStore();
  const websites = useWebsitesQuery(orgId, selectedCompany?.id || "");
  const widgetIds = dashboardQuery.data?.widgets.map((w) => w.id) || [];
  const widgetsQuery = useWidgets({ orgId, dealId, widgetIds });
  const refreshWidgetJob = useRefreshWidgetGenerationJob({ orgId, dealId });
  const refreshingWidgetJobs = useWidgetGenerationJobs({
    orgId,
    dealId,
    status: ["pending", "in_progress"],
  });
  const domainLogoQuery = useDomainLogo({
    domain: getCompanyLogoDomain(websites.data) || "",
  });
  const widgetsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dashboardQuery.data?.widgets?.forEach((widget) => {
      if (widget.status === "pending") {
        handleRefresh(widget.id);
      }
    });
  }, [dashboardQuery.data]);

  const refreshingIds = useMemo(() => {
    return (
      refreshingWidgetJobs.data
        ?.map((job) => job.widgetId)
        .filter((id) => widgetIds.includes(id)) || []
    );
  }, [refreshingWidgetJobs.data, widgetIds]);

  useEffect(() => {
    if (dealQuery.data) {
      setSelectedCompany(dealQuery.data);
    }
    resetFocusedWidget();
    setContextWindowState(null);
    closeSourceViewer();
  }, [dealQuery.data]);

  const noData = useMemo(() => {
    if (!dashboardQuery.data) return true;
    if (dashboardQuery.data.widgets.length === 0) return true;
    return widgetsQuery.every((w) => !w.data || w.data.empty);
  }, [dashboardQuery.data, widgetsQuery]);

  const handleRefresh = (id: string) => {
    refreshWidgetJob.mutate({ widgetId: id });
  };

  const triggerRefresh = useCallback(() => {
    widgetIds.forEach((id) => {
      handleRefresh(id);
    });
  }, [widgetIds, refreshWidgetJob]);

  const oneLinerContent = useMemo(() => {
    const widgets = dashboardQuery.data?.widgets || [];
    const oneLinerWidget = widgets.find((w) => w.name === "company_one_liner");
    if (!oneLinerWidget || widgetsQuery[0].data?.contentType !== "topic_list") {
      return undefined;
    }
    const content = widgetsQuery[0].data.content as Topic[];
    return removeCitations(
      content[0]?.entry?.options[0]?.content?.replace(/^- /, "") || "",
    );
  }, [dashboardQuery.data, widgetsQuery]);

  const mainWidgets = useMemo(
    () =>
      widgetsQuery
        .filter((w) => w.data?.name !== "company_one_liner")
        .map((w) => w.data)
        .filter((w) => !!w) || [],
    [widgetsQuery],
  );

  useEffect(() => {
    if (widgetsRef.current) {
      const widget = widgetsRef.current.querySelector(
        `[data-widget-focused="true"]`,
      );
      if (widget) {
        setTimeout(() => {
          widget.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0);
      }
    }
  }, [focusedWidget]);

  return (
    <div className="h-screen w-full flex flex-col">
      <DashboardExport
        companyName={dealQuery.data?.company_name || ""}
        companyDescription={oneLinerContent || ""}
        widgets={mainWidgets}
        companyLogo={domainLogoQuery.data?.url}
      />
      <div className="h-14 w-full shrink-0" />
      <ResizablePanelGroup direction="horizontal" className="h-full">
        <ResizablePanel minSize={20} id="widgets" order={0}>
          <div
            className="flex flex-1 flex-col gap-3 p-4 bg h-full overflow-auto"
            ref={widgetsRef}
          >
            <DashboardTitle
              companyName={dealQuery.data?.company_name || ""}
              companyDescription={oneLinerContent || ""}
              refreshing={refreshingIds.length > 0}
              onRefresh={triggerRefresh}
              noData={noData}
              widgets={mainWidgets}
            />
            {noData && <EmptyDashboard />}
            <div className="@container contain-layout">
              <div
                id="dashboard-grid"
                className={cn(
                  "grid grid-cols-1 @6xl:grid-cols-2 gap-3",
                  (sourceViewerOpen || contextWindowState) && "!grid-cols-1",
                )}
              >
                {widgetsQuery?.map(
                  (query, index) =>
                    dashboardQuery.data?.widgets[index].name !==
                      "company_one_liner" && (
                      <WidgetContent
                        key={widgetIds[index]}
                        query={query}
                        onRefresh={() => handleRefresh(widgetIds[index])}
                        refreshing={refreshingIds.includes(widgetIds[index])}
                      />
                    ),
                )}
              </div>
            </div>
          </div>
        </ResizablePanel>
        {contextWindowState && <ContextWindow widgets={mainWidgets} />}
        {source && <DashboardSourceViewer />}
      </ResizablePanelGroup>
    </div>
  );
};
