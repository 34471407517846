import { WidgetCitation } from "@/types/widgets";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";
import { ContextWindowState } from "../types/state";

const initialState = {
  refreshing: false,
  exportDialogOpen: false,
  contextWindowState: null as ContextWindowState,
  sourceViewerOpen: false,
  source: undefined as WidgetCitation | undefined,
  focusedWidget: undefined as string | undefined,
};

export const useDashboardStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),
        setRefreshing: (refreshing: boolean) => set(() => ({ refreshing })),
        setExportDialogOpen: (exportDialogOpen: boolean) =>
          set(() => ({ exportDialogOpen })),
        openSourceViewer: () => set({ sourceViewerOpen: true }),
        closeSourceViewer: () => set({ sourceViewerOpen: false }),
        setSource: (content: WidgetCitation) => set({ source: content }),
        resetSource: () => set({ source: undefined }),
        setContextWindowState: (state: ContextWindowState) =>
          set({ contextWindowState: state }),
        closeContextWindow: () => set({ contextWindowState: null }),
        setFocusedWidget: (widgetId: string) =>
          set({ focusedWidget: widgetId }),
        resetFocusedWidget: () => set({ focusedWidget: undefined }),
      })),
    ),
    {
      name: "DashboardStore",
    },
  ),
);

useDashboardStore.subscribe(
  (state) => state.contextWindowState,
  (contextWindowState) => {
    const { resetFocusedWidget } = useDashboardStore.getState();
    if (!contextWindowState) {
      resetFocusedWidget();
    }
  },
);

useDashboardStore.subscribe(
  (state) => state.source,
  (source) => {
    const { openSourceViewer } = useDashboardStore.getState();
    if (source) {
      openSourceViewer();
    }
  },
);
