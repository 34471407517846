import React, { useEffect } from "react";
import { Text, Spinner } from "capsa-ui";
import { useRegistryDocumentsQuery } from "@/components/CompanySearch/hooks/registryDocuments";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";
import { useValidatedParams } from "@/utils/router";

export const RegistryDocuments = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const {
    selectedCompanySuggestion,
    filteredDocuments,
    legalEntity,
    setRegistryDocuments,
    setFilteredDocuments,
    setLoading,
    setFilterOptions,
    setSelectedFilterOptions,
  } = useRegistrySearchStore();

  const docs = useRegistryDocumentsQuery(
    orgId || "",
    legalEntity?.name || "",
    legalEntity?.id === selectedCompanySuggestion?.id
      ? selectedCompanySuggestion?.countryCode || ""
      : "",
    legalEntity?.id || "",
  );

  useEffect(() => {
    if (docs.data) {
      setRegistryDocuments(docs.data || []);
      setFilteredDocuments(docs.data || []);

      const filterOptions = docs.data.map((doc) => ({
        label: doc.effectiveDate?.substring(0, 4) || "",
        value: doc.effectiveDate?.substring(0, 4) || "",
      }));

      const selectedFilterOptions = docs.data.reduce((acc, doc) => {
        const year = doc.effectiveDate?.substring(0, 4) || "";
        if (!acc.some((option) => option === year)) {
          const mostRecentYear = Math.max(
            ...docs.data.map((doc) =>
              parseInt(doc.effectiveDate?.substring(0, 4) || "0"),
            ),
          );
          if (mostRecentYear - parseInt(year) <= 4) {
            acc.push(year);
          }
        }
        return acc;
      }, [] as string[]);

      setFilterOptions(filterOptions);
      setSelectedFilterOptions(selectedFilterOptions);
    } else {
      setRegistryDocuments([]);
      setFilteredDocuments([]);
      setFilterOptions([]);
      setSelectedFilterOptions([]);
    }
  }, [docs.data]);

  useEffect(() => {
    setLoading(docs.isLoading);
  }, [docs.isLoading]);

  if (docs.isLoading) {
    return (
      <div className="flex flex-col gap-4 justify-center items-center w-full h-full">
        <Spinner size="lg" />
        <Text color="subtle" type="label">
          Finding documents...
        </Text>
      </div>
    );
  }

  if (docs.error || filteredDocuments.length === 0) {
    return (
      <div className="px-3 py-2">
        <Text type="label" color="subtle">
          No documents found.
        </Text>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        {filteredDocuments.map((doc) => (
          <div
            key={doc.documentId}
            className="w-full flex items-start gap-6 px-3 py-2 border-b last:border-b-0"
          >
            <div className="flex-1 display flex flex-col">
              <Text type="label">{doc.documentName}</Text>
            </div>
            <Text type="caption" color="subtle">
              {doc.effectiveDate?.substring(0, 4)}
            </Text>
          </div>
        ))}
      </div>
    </>
  );
};
