import { baseURL, resolveFetch } from "@/api/shared";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { QueryConfig } from "@/lib/reactQuery";
import { User } from "@/types";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { Thread } from "../types/api";

export const getThread = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
  threadId: string,
): Promise<Thread> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}/assistant-threads/${threadId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

type useThreadOptions = {
  orgId: string;
  dealId: string;
  widgetId: string;
  threadId?: string;
  config?: QueryConfig<typeof getThread>;
};

export const useThread = ({
  orgId,
  dealId,
  widgetId,
  threadId,
  config,
}: useThreadOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "widgets",
      widgetId,
      "assistant-threads",
      threadId,
    ],
    queryFn: () => {
      if (!threadId) {
        return null;
      }
      return getThread(user, orgId, dealId, widgetId, threadId);
    },
    enabled: !!user && !!threadId,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data?.status === "pending" || data?.status === "processing") {
        return 1000;
      }
      return false;
    },
    ...config,
  });
};
