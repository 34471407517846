import React, { ReactNode, useMemo, useRef, useState } from "react";
import { cn } from "../../../../lib/utils";
import {
  Button,
  Divider,
  ScrollArea,
  Spinner,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../ui";
import { Icon } from "../../../icons";
import { AnimatePresence } from "framer-motion";

export interface Citation {
  citationNumber: number;
  searchResult: SearchResultWeb | SearchResultFile;
}

export interface SearchResultWeb {
  type: "webpage";
  id: string;
  websiteId: string;
  webpageId: string | null;
  url: string | null;
}

export interface SearchResultFile {
  type: "file";
  id: string;
  fileId: string;
  fileName: string | null;
  pageNumber: number | null;
}
export interface WidgetRenderProps {
  setClipboard?: (items: ClipboardItem) => void;
  onRefresh?: () => void;
  onSource?: (source: Citation) => void;
  refreshing?: boolean;
  widgetRefreshing?: boolean;
  loading?: boolean;
  filters?: React.ReactNode;
}

export interface WidgetProps {
  children?: (props: WidgetRenderProps) => ReactNode;
  title?: string;
  loading?: boolean;
  refreshing?: boolean;
  className?: string;
  onSource?: (source: Citation) => void;
  onRefresh?: () => void;
  onCopy?: (items: ClipboardItem) => void;
  isEmpty?: boolean;
  isStale?: boolean;
  focused?: boolean;
  actions?: (props: {
    clipboard?: ClipboardItem | null;
    loading?: boolean;
    refreshing?: boolean;
    focused?: boolean;
  }) => React.ReactNode | React.ReactNode[];
}

const Empty = () => (
  <div className="flex-1 flex-center gap-1 text-faint w-full min-h-16 border border-subtle border-dashed rounded">
    <Icon type="info" size="xs" />
    <Text type="label" color="faint" className="text-center">
      No data found.
    </Text>
  </div>
);

export const Widget = React.forwardRef<HTMLDivElement, WidgetProps>(
  (
    {
      children,
      className,
      title,
      loading,
      refreshing,
      onSource,
      onRefresh,
      onCopy,
      isEmpty,
      isStale,
      focused,
      actions,
      ...props
    },
    ref,
  ) => {
    const elementRef = useRef(null);
    const widgetRef = (ref || elementRef) as React.RefObject<HTMLInputElement>;
    const [clipboard, setClipboard] = useState<ClipboardItem | null>(null);

    const content = useMemo(() => {
      if (loading) {
        return (
          <div className="flex-center p-8">
            <div className="absolute rounded inset-0 bg-secondary animate-pulse" />
            <Spinner />
          </div>
        );
      }

      if (isEmpty || !children) {
        if (refreshing) {
          return (
            <div className="flex-1 flex flex-col min-h-16 border border-transparent gap-1">
              <div className="w-full flex-1 bg-secondary animate-pulse rounded" />
              <div className="w-full flex-1 bg-tertiary animate-pulse rounded" />
            </div>
          );
        }
        return <Empty />;
      }

      if (!children) return <Empty />;

      const entries = children({
        onRefresh,
        onSource,
        refreshing,
        loading,
        setClipboard,
      });

      return (
        <ScrollArea className="p-2">
          <AnimatePresence initial={false}>{entries}</AnimatePresence>
        </ScrollArea>
      );
    }, [onCopy, onRefresh, onSource, refreshing, loading, children, widgetRef]);

    return (
      <div
        ref={ref}
        className={cn(
          "widget flex flex-col w-full relative border rounded-layout bg",
          (refreshing || loading) && "border-subtle",
          focused && "border-primary shadow-pulse",
          className,
        )}
        data-widget-focused={focused}
        {...props}
      >
        {!loading && (
          <>
            <header
              className={cn(
                "flex items-center pl-3 pr-1.5 h-11 gap-1.5 relative overflow-hidden m-1",
                (refreshing || loading) && "rounded-b-none",
                className,
              )}
            >
              <div
                className={cn(
                  "absolute rounded-t inset-0 bg-secondary transition-colors",
                  (refreshing || loading) && "animate-loading",
                  focused && "bg-primary-highlight",
                )}
              />
              <Text
                weight="bold"
                className={cn(
                  "relative",
                  refreshing && "animate-pulse",
                  focused && "text-primary",
                )}
              >
                {title}
              </Text>
              {onRefresh && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={onRefresh}
                      loading={refreshing || loading}
                      iconLeft="refresh"
                      className="relative"
                    />
                  </TooltipTrigger>
                  <TooltipContent>Refresh</TooltipContent>
                </Tooltip>
              )}
              <div className="relative">
                {isStale && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon
                        type="alertFilled"
                        size="xs"
                        className="text-gray-400 tooltip"
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      The widget template has been updated. Refresh the widget
                      to apply the latest template.
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
              <div className="flex-1" />
              <div className="flex gap-0.5">
                {actions && (
                  <>{actions({ clipboard, loading, refreshing, focused })}</>
                )}
              </div>
            </header>
            <Divider
              className={cn(refreshing && "border-subtle animate-pulse")}
            />
          </>
        )}
        <div className="flex-1 flex flex-col p-1">{content}</div>
      </div>
    );
  },
);
Widget.displayName = "Widget";
