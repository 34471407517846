import * as React from "react";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { cn } from "../../..";

interface UseAutosizeTextAreaProps {
  textAreaRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  minHeight?: number;
  maxHeight?: number;
  triggerAutoSize: string;
  autoSizePadding?: number;
}

export const useAutosizeTextArea = ({
  textAreaRef,
  triggerAutoSize,
  maxHeight = Number.MAX_SAFE_INTEGER,
  minHeight = 0,
  autoSizePadding = 0,
}: UseAutosizeTextAreaProps) => {
  const [init, setInit] = React.useState(true);
  React.useEffect(() => {
    const offsetBorder = 6;
    const textAreaElement = textAreaRef.current;
    if (textAreaElement) {
      if (init) {
        textAreaElement.style.minHeight = `${minHeight + offsetBorder + autoSizePadding}px`;
        if (maxHeight > minHeight) {
          textAreaElement.style.maxHeight = `${maxHeight}px`;
        }
        setInit(false);
      }
      textAreaElement.style.height = `${minHeight + offsetBorder + autoSizePadding}px`;
      const scrollHeight = textAreaElement.scrollHeight;
      if (scrollHeight > maxHeight) {
        textAreaElement.style.height = `${maxHeight}px`;
      } else {
        textAreaElement.style.height = `${scrollHeight + offsetBorder + autoSizePadding}px`;
      }
    }
  }, [textAreaRef.current, triggerAutoSize]);
};

export type TextAreaRef = {
  textArea: HTMLTextAreaElement;
  maxHeight: number;
  minHeight: number;
};

type TextAreaProps = {
  maxHeight?: number;
  minHeight?: number;
  autoSize?: boolean;
  autoSizePadding?: number;
  label?: string;
  inputClassName?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = React.forwardRef<TextAreaRef, TextAreaProps>(
  (
    {
      maxHeight = Number.MAX_SAFE_INTEGER,
      minHeight = 52,
      onChange,
      value,
      className,
      autoSize = false,
      autoSizePadding = 0,
      label,
      inputClassName,
      inputRef,
      ...props
    }: TextAreaProps,
    ref: React.Ref<TextAreaRef>,
  ) => {
    const elementRef = useRef<HTMLTextAreaElement>(null);
    const textAreaRef = inputRef || elementRef;
    const [triggerAutoSize, setTriggerAutoSize] = React.useState("");
    const [focused, setFocused] = useState(false);

    if (autoSize) {
      useAutosizeTextArea({
        textAreaRef,
        triggerAutoSize: triggerAutoSize,
        maxHeight,
        minHeight,
        autoSizePadding,
      });
    }

    useImperativeHandle(ref, () => ({
      textArea: textAreaRef.current as HTMLTextAreaElement,
      focus: () => textAreaRef?.current?.focus(),
      maxHeight,
      minHeight,
    }));

    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    useEffect(() => {
      setTriggerAutoSize(value as string);
    }, [props?.defaultValue, value]);

    return (
      <div
        className={cn(
          "w-full flex flex-col gap-1.5 relative",
          className,
          props.disabled && "disabled select-none",
        )}
      >
        {label && (
          <label
            htmlFor={props.id || props.name || label}
            className={cn(
              "flex items-center gap-1 text-subtle text-label font-bold shrink-0",
              focused && "text-primary",
            )}
          >
            {label}
            {props.required && <span className="text-primary">*</span>}
          </label>
        )}
        <textarea
          id={props.id || label}
          value={value}
          ref={textAreaRef}
          onChange={(e) => {
            if (autoSize) {
              setTriggerAutoSize(e.target.value);
            }
            onChange?.(e);
          }}
          className={cn(
            "focus-field flex min-h-[80px] w-full rounded border-default bg p-3 text-body placeholder:text-faint",
            props.disabled && "disabled select-none",
            inputClassName,
          )}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
      </div>
    );
  },
);
TextArea.displayName = "TextArea";

export { TextArea };
