import {
  Button,
  MultiSelect,
  Toggle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Widget,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import { WidgetContentBulletPoints } from "@/features/dashboards/components/widget/WidgetContentBulletPoints";
import { WidgetContentTimeline } from "@/features/dashboards/components/widget/WidgetContentTimeline";
import { WidgetContentTable } from "@/features/dashboards/components/widget/WidgetContentTable";
import {
  TimeSeries,
  Topic,
  Event,
  Person,
  Widget as WidgetType,
  WidgetCitation,
} from "@/types/widgets";
import { WidgetContentPersonList } from "@/features/dashboards/components/widget/WidgetContentPersonList";
import { QueryObserverResult } from "@tanstack/react-query";
import { copyToClipboard } from "@/utils/copy";
import { useDealFilesQuery } from "@/hooks/deals";
import { useValidatedParams } from "@/utils/router";
import { useWebsitesQuery } from "@/hooks/websites";
import { useUpdateWidget } from "../../api/updateWidget";
import { useDashboardStore } from "../../store/useDashboardStore";

export const WidgetContent = ({
  query,
  onRefresh,
  refreshing,
}: {
  query: QueryObserverResult<WidgetType, Error>;
  onRefresh: () => void;
  refreshing: boolean;
}) => {
  const { data, isLoading } = query;
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const websitesQuery = useWebsitesQuery(orgId, dealId);
  const [filteredSources, setFilteredSources] = useState<string[]>([]);
  const updateWidget = useUpdateWidget({
    orgId,
    dealId,
    widgetId: data?.id || "",
  });
  const {
    setSource,
    openSourceViewer,
    setContextWindowState,
    setFocusedWidget,
    focusedWidget,
  } = useDashboardStore();

  useEffect(() => {
    // set initial filteredSources
    if (data && data.localContentGenerationParams) {
      const { fileIds, websiteIds } = data.localContentGenerationParams;
      const sources = [...(fileIds || []), ...(websiteIds || [])];
      setFilteredSources(sources);
    }
  }, [data]);

  const handleSourceClick = (citation: WidgetCitation) => {
    setSource(citation);
    openSourceViewer();
  };

  const title = useMemo(() => {
    if (!data) return "";
    return data.prettyName;
  }, [data]);

  const handleCopy = (items: ClipboardItem) => {
    copyToClipboard(items);
  };

  const handleFilterChange = async (ids: string[]) => {
    if (!data) return;
    try {
      const sources = ids
        .map((id) => filterableSources.find((source) => source.value === id))
        .filter((source) => !!source);
      const fileIds = sources
        .filter((source) => source && source?.type === "file")
        .map((source) => source?.value);
      const websiteIds =
        sources
          .filter((source) => source?.type === "website")
          .map((source) => source?.value) || [];
      updateWidget.mutateAsync({
        localContentGenerationParams: {
          ...data.localContentGenerationParams,
          fileIds,
          websiteIds,
        },
      });
    } catch (error) {
      console.error(error);
    }
    setFilteredSources(ids);
    onRefresh();
  };

  const filterableSources = useMemo(() => {
    const filesList =
      filesQuery.data?.map((item) => ({
        value: item.id,
        label: item.name,
        type: "file",
      })) || [];
    const websitesList =
      websitesQuery.data?.map((item) => ({
        value: item.id,
        label: item.url.replace("https://", "").replace(/\/$/, ""),
        type: "website",
      })) || [];
    return [...filesList, ...websitesList];
  }, [filesQuery.data, websitesQuery.data]);

  if (!data) return null;

  return (
    <Widget
      loading={isLoading}
      refreshing={refreshing || (data && data.status === "refreshing")}
      onSource={handleSourceClick}
      title={title}
      onRefresh={onRefresh}
      onCopy={handleCopy}
      isEmpty={data.empty}
      isStale={
        data.contentGenerationParamsHash !=
        data.widgetTemplateContentGenerationParamsHash
      }
      focused={focusedWidget === data.id}
      actions={({ clipboard, loading, refreshing, focused }) => [
        <MultiSelect
          key="filters"
          options={filterableSources}
          onValueChange={handleFilterChange}
          updateOnSave
          selected={filteredSources}
          variant="ghost"
          label="Filter Sources"
          optionsLabel="Sources"
          message="Select sources to use when generating this widget, or leave empty to use all available."
          size="sm"
          hasReset
          disabled={refreshing || (data && data.status === "refreshing")}
          popoverContentProps={{
            side: "bottom",
            align: "end",
            className: "min-w-[350px] p-0",
          }}
        />,
        <Tooltip key="copy">
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => clipboard && handleCopy(clipboard)}
              disabled={refreshing || loading}
              iconLeft="copy"
              className="relative"
            />
          </TooltipTrigger>
          <TooltipContent>Copy</TooltipContent>
        </Tooltip>,
        <Toggle
          key="assistant"
          variant="ghost"
          size="sm"
          iconLeft="capsa"
          className="relative"
          pressed={focused}
          onPressedChange={(pressed) => {
            if (pressed) {
              setFocusedWidget(data.id);
              setContextWindowState("assistant");
            } else {
              setContextWindowState(null);
            }
          }}
        />,
      ]}
    >
      {({ ...props }) => (
        <>
          {data.content && data.contentType === "topic_list" && (
            <WidgetContentBulletPoints
              content={data.content as Topic[]}
              widgetId={data.id}
              {...props}
            />
          )}
          {data.content && data.contentType === "event_list" && (
            <WidgetContentTimeline
              content={data.content as Event[]}
              widgetId={data.id}
              {...props}
            />
          )}
          {data.content && data.contentType === "time_series" && (
            <WidgetContentTable
              content={data.content as TimeSeries[]}
              widgetId={data.id}
              {...props}
            />
          )}
          {data.content && data.contentType === "person_role_list" && (
            <WidgetContentPersonList
              content={data.content as Person[]}
              widgetId={data.id}
              {...props}
            />
          )}
        </>
      )}
    </Widget>
  );
};
