import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";

const initialState = {
  threadId: undefined as string | undefined,
};

export const useAssistantStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),
        setThreadId: (threadId: string) => set(() => ({ threadId })),
        resetThreadId: () => set(() => ({ threadId: undefined })),
      })),
    ),
    {
      name: "AssistantStore",
    },
  ),
);
