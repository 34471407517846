import DashboardTable from "../components/DashboardTable";
import { useValidatedParams } from "@/utils/router";

import { Avatar, Heading, ProgressBar, Spinner, Text, cn } from "capsa-ui";
import { ReactNode } from "react";
import ReactMarkdown from "react-markdown";

interface Props {
  avatar?: string;
  children?: ReactNode | ReactNode[];
  actions?: ReactNode[];
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  refreshing?: boolean;
  loading?: boolean;
}

export const DashboardTemplatesConfigurationLayout = ({
  avatar,
  children,
  actions,
  title,
  description,
  className,
  refreshing,
  loading,
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-1 flex-col gap-3 p-4 pt-1 bg min-h-0",
        className,
      )}
    >
      <div className="h-14 w-full shrink-0" />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {(actions || title || description) && (
            <header
              className={cn(
                "flex items-end border-b pb-3 gap-20 relative",
                refreshing && "border-b-transparent",
              )}
            >
              <div className="flex items-start gap-2.5 flex-1">
                {avatar && (
                  <div className="flex-center p-1.5 rounded-layout border shrink-0">
                    <Avatar src={avatar} size="hero" />
                  </div>
                )}
                <div className="flex flex-col gap-1 flex-1">
                  {title && <Heading>{title}</Heading>}
                  {description && (
                    <div>
                      <Text type="label" color="subtle">
                        <ReactMarkdown
                          components={{
                            p: ({ children }) => (
                              <p className="mb-2">{children}</p>
                            ),
                            ul: ({ children }) => (
                              <ul className="list-disc ml-4 mb-1">
                                {children}
                              </ul>
                            ),
                            li: ({ children }) => (
                              <li className="mb-0">{children}</li>
                            ),
                          }}
                        >
                          {String(description)}
                        </ReactMarkdown>
                      </Text>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex gap-1 flex-wrap max-w-[50%] justify-end">
                {actions}
              </div>
              {refreshing && (
                <ProgressBar
                  value={null}
                  className="absolute bottom-[-1px] left-0 right-0 h-0.5"
                />
              )}
            </header>
          )}
          {children}
        </>
      )}
    </div>
  );
};

export const DashboardTemplatesConfigurationView = () => {
  const { orgId } = useValidatedParams(["orgId"]);

  return (
    <DashboardTemplatesConfigurationLayout
      title={"Dashboard Templates"}
      description={
        "\n\nConfigure dashboards available for each deal and set which one appears by " +
        "default in Company Profile.\n\n" +
        "- **System Generated** dashboards cannot be edited. If you'd like to create a " +
        "version of a system generated dashboard, duplicate it and edit the copy.\n\n" +
        "- **Active dashboards** are used to calculate which widgets should be generated " +
        "when new information is added to a deal. If Dashboard A has widgets 1,2; " +
        "Dashboard B has widgets 2,3; but only Dashboard A is Active, when new " +
        "information is ingested, we will only generate widgets 1 and 2, as widget 3 " +
        "does not belong to any active dashboard.  \n" +
        "For now this is mostly an optimization to avoid generating widgets that are " +
        "not in use. In the future, when showing multiple dashboards is supported, " +
        "Active dashboards are the ones that will be shown to the users.\n\n" +
        "- The **Default dashboard** determines which dashboard users see when " +
        "accessing Company Profile."
      }
    >
      <DashboardTable orgId={orgId} />
    </DashboardTemplatesConfigurationLayout>
  );
};
