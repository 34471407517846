import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { QueryConfig } from "@/lib/reactQuery";
import { Widget } from "@/types/widgets";

export const getWidget = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
): Promise<Widget> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

type useWidgetOptions = {
  user: User;
  orgId: string;
  dealId: string;
  widgetId: string;
  config?: QueryConfig<typeof getWidget>;
};

export const widgetQuery = ({
  user,
  orgId,
  dealId,
  widgetId,
  config,
}: useWidgetOptions) => ({
  queryKey: [orgId, "deals", dealId, "widgets", widgetId],
  queryFn: () => getWidget(user, orgId, dealId, widgetId),
  enabled: !!user,
  retry: defaultRetryFunc,
  refetchInterval: (data: Widget | undefined) => {
    if (data && ["pending", "scheduled", "refreshing"].includes(data.status)) {
      return 2000;
    }
    return 10000;
  },
  ...config,
});

export const useWidget = (orgId: string, dealId: string, widgetId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery(widgetQuery({ user, orgId, dealId, widgetId }));
};
