import { Clickable, Icon, Text, Skeleton, Spinner } from "capsa-ui";
import { useThreads } from "../api/getThreads";
import { useValidatedParams } from "@/utils/router";
import { useAssistantStore } from "../store/useAssistantStore";
import { useMemo } from "react";
import moment from "moment";

type Props = {
  widgetId: string;
};

export const ChatHistory = ({ widgetId }: Props) => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { setThreadId } = useAssistantStore();
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useThreads({
      orgId,
      dealId,
      widgetId: widgetId,
    });

  const onSelect = (threadId: string) => {
    setThreadId(threadId);
  };

  const threads = useMemo(() => {
    if (!data?.pages) return [];

    const threadIds = new Set<string>();

    return data.pages
      .flatMap((page) => page?.data || [])
      .filter((thread) => {
        if (threadIds.has(thread.id)) {
          return false;
        }
        threadIds.add(thread.id);
        return true;
      });
  }, [data?.pages]);

  if (threads.length === 0) return null;

  return (
    <>
      <div className="flex flex-col gap-2 px-3 max-h-full min-h-0">
        <div className="flex gap-1.5 items-center">
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Icon type="history" size="sm" />
          )}
          <Text weight="medium" type="label" color="subtle">
            History
          </Text>
          <div className="flex-1" />
        </div>
        <div className="flex flex-col gap-1 flex-1 overflow-auto">
          {threads.map((thread) => (
            <Clickable
              className="bg-secondary"
              key={thread.id}
              onClick={() => onSelect(thread.id)}
            >
              <Text className="flex-1">
                {thread.state.title_parts.length > 0
                  ? thread.state.title_parts.join("")
                  : "Thread"}
              </Text>
              <span className="text-subtle text-label text-right">
                {moment(thread.updated_at).format("L HH:mm")}
              </span>
              <Icon type="chevronRight" size="sm" className="text-faint" />
            </Clickable>
          ))}
          {isFetchingNextPage ? (
            <Skeleton className="w-full p-3">
              <Text className="opacity-0">Loading</Text>
            </Skeleton>
          ) : (
            hasNextPage && (
              <Clickable
                className="bg-secondary"
                onClick={() => fetchNextPage()}
              >
                <Text>Load more</Text>
                <div className="flex-1" />
                <Icon type="chevronDown" size="sm" className="text-faint" />
              </Clickable>
            )
          )}
        </div>
      </div>
    </>
  );
};
