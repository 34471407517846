import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { JobStatus, WidgetGenerationJob } from "../types/api";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { useQuery } from "@tanstack/react-query";
import { assertUserIsAuthenticated } from "@/utils";
import { QueryConfig } from "@/lib/reactQuery";

export const getWidgetGenerationJobs = async (
  user: User,
  orgId: string,
  dealId: string,
  status?: JobStatus[],
): Promise<WidgetGenerationJob[]> => {
  const url = new URL(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/widget-generation-jobs`,
  );
  if (status) {
    status.forEach((s) => url.searchParams.append("status", s));
  }
  return await resolveFetch(
    fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

type useWidgetGenerationJobsOptions = {
  orgId: string;
  dealId: string;
  status?: JobStatus[];
  config?: QueryConfig<typeof getWidgetGenerationJobs>;
};

export const useWidgetGenerationJobs = ({
  orgId,
  dealId,
  status,
  config,
}: useWidgetGenerationJobsOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "widget-generation-jobs"],
    queryFn: () => getWidgetGenerationJobs(user, orgId, dealId, status),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (
        data &&
        data.some((job) => ["pending", "in_progress"].includes(job.status))
      ) {
        return 1000;
      }
      return false;
    },
    ...config,
  });
};
