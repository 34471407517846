import { createTV } from "tailwind-variants";

export const withVariants = createTV({
  twMergeConfig: {
    classGroups: {
      fontSize: [
        "text-h1",
        "text-h2",
        "text-h3",
        "text-subheading",
        "text-body",
        "text-label",
        "text-caption",
      ],
      textColor: [
        "text",
        "text-inherit",
        "text-current",
        "text-transparent",
        "text-subtle",
        "text-faint",
        "text-inverted",
        "text-primary",
        "text-success",
        "text-warning",
        "text-danger",
        "text-brand-text",
        "text-brand-mark",

        // components
        "button-primary-text",
        "button-danger-text",
      ],
      backgroundColor: [
        "bg",
        "bg-inherit",
        "bg-current",
        "bg-transparent",
        "bg-secondary",
        "bg-tertiary",
        "bg-inverted",
        "bg-overlay",
        "bg-primary",
        "bg-primary-highlight",
        "bg-primary-accent",
        "bg-success",
        "bg-success-accent",
        "bg-warning",
        "bg-warning-accent",
        "bg-danger",
        "bg-danger-accent",

        // components
        "button-primary-bg",
        "button-primary-edge",
        "button-danger-bg",
        "button-danger-edge",
      ],
    },
    conflictingClassGroups: {
      fontSize: ["fontSize"],
      textColor: ["textColor"],
    },
  },
});
