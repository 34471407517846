import React, { useEffect } from "react";
import { Text, TooltipIcon, MultiSelect, Autocomplete } from "capsa-ui";
import { EntitiesFlowDiagram } from "@/features/registrySearch/components/EntitiesFlowChart";
import { getName } from "country-list";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";
import {
  useComapanySuggestionsExtended,
  useCompanySuggestionsQuick,
} from "@/features/companies/hooks/useCompanySuggestions";
import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import { Company } from "@/features/companies/types/company";
import { CompanyNameSuggestion } from "@/components/CompanySearch/api/listCompanyNameSuggestions";
import { RegistryDocuments } from "@/features/registrySearch/components/RegistryDocuments";
import { useDebounce } from "use-debounce";

type Props = {
  initialSearchValue?: string;
  onDocumentsChange?: (documents: RegistryDocument[]) => void;
  onLegalEntityChange?: (legalEntity: Company | null) => void;
  onCompanySuggestionChange?: (selected: CompanyNameSuggestion | null) => void;
  onLoading?: (loading: boolean) => void;
};

const RegistrySearch = ({
  initialSearchValue,
  onDocumentsChange,
  onCompanySuggestionChange,
  onLegalEntityChange,
  onLoading,
}: Props) => {
  const {
    registrySearch,
    setRegistrySearch,
    companySuggestions = [],
    selectCompanySuggestion,
    selectedCompanySuggestion,
    filteredDocuments,
    legalEntity,
    setCompanySuggestions,
    loading,
    filterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
  } = useRegistrySearchStore();
  const [debouncedSearch] = useDebounce(registrySearch, 300);
  const quickSuggestions = useCompanySuggestionsQuick(debouncedSearch);
  const extendedSuggestions = useComapanySuggestionsExtended(debouncedSearch);

  useEffect(() => {
    const allSuggestions = new Map();
    if (quickSuggestions.data) {
      quickSuggestions.data.forEach((suggestion) => {
        const key = `${suggestion.companyName}-${suggestion.countryCode}`;
        allSuggestions.set(key, suggestion);
      });
    }
    if (extendedSuggestions.data) {
      extendedSuggestions.data.forEach((suggestion) => {
        const key = `${suggestion.companyName}-${suggestion.countryCode}`;
        allSuggestions.set(key, suggestion);
      });
    }
    setCompanySuggestions(Array.from(allSuggestions.values()));
  }, [quickSuggestions.data, extendedSuggestions.data]);

  useEffect(() => {
    if (initialSearchValue) {
      setRegistrySearch(initialSearchValue);
    }
  }, []);

  useEffect(() => {
    if (onDocumentsChange) {
      onDocumentsChange(filteredDocuments);
    }
  }, [filteredDocuments]);

  useEffect(() => {
    if (onLegalEntityChange) {
      onLegalEntityChange(legalEntity);
      if (legalEntity?.name) {
        setRegistrySearch(legalEntity.name);
      }
    }
  }, [legalEntity]);

  useEffect(() => {
    if (onCompanySuggestionChange) {
      onCompanySuggestionChange(selectedCompanySuggestion);
    }
  }, [selectedCompanySuggestion]);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <Autocomplete
          searchValue={registrySearch}
          onSearchValueChange={setRegistrySearch}
          items={
            companySuggestions?.map((suggestion) => ({
              value: suggestion.graphId,
              label: suggestion.companyName,
              description: getName(suggestion.countryCode),
            })) || []
          }
          onSelect={(id) => {
            const company = companySuggestions.find(
              (suggestion) => suggestion.graphId === id,
            );
            if (company) {
              selectCompanySuggestion(company);
              setRegistrySearch(company.companyName);
            }
          }}
          isLoading={quickSuggestions.isLoading}
          loadingText="Searching for legal entities..."
          placeholder="Search by legal entity name, e.g. Formel D"
          emptyMessage="No legal entities found"
          textFieldProps={{
            autoFocus: !legalEntity?.id,
          }}
        />
      </div>
      {selectedCompanySuggestion?.id && (
        <div className="flex gap-3">
          <div className="flex flex-col gap-2.5 h-[500px] flex-1">
            <div className="flex gap-1 items-center">
              <Text className="pl-3" type="label" color="subtle" weight="bold">
                Select Legal Entity
              </Text>
              <TooltipIcon>
                Navigate the company hierarchy on the graph to choose the top
                legal entity.
              </TooltipIcon>
            </div>
            <div className="flex border rounded-layout h-full overflow-hidden">
              <div className="w-full bg-secondary">
                <EntitiesFlowDiagram />
              </div>
            </div>
          </div>
          {legalEntity?.id && (
            <div className="flex flex-col gap-2.5 h-[500px] w-96">
              <div className="relative flex justify-between items-center">
                <Text
                  className="pl-3"
                  type="label"
                  color="subtle"
                  weight="bold"
                >
                  Registry Documents
                </Text>
                {filterOptions.length > 0 && (
                  <div className="flex flex-1 justify-end h-4 items-center">
                    <MultiSelect
                      size="sm"
                      options={filterOptions}
                      selected={selectedFilterOptions}
                      onValueChange={(value) => {
                        setSelectedFilterOptions(value);
                      }}
                      label="Filter"
                      optionsLabel="Years"
                      hasReset
                    />
                  </div>
                )}
              </div>
              <div className="border rounded-layout h-full overflow-scroll">
                <RegistryDocuments />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RegistrySearch;
