import type React from "react";
import { cn } from "../../../lib/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import invariant from "tiny-invariant";
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import {
  BaseEventPayload,
  ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index"; //NEW
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { Icon } from "../../icons";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { createPortal } from "react-dom";
import { LayoutGroup, motion } from "framer-motion";

export interface DragAndDropZoneProps {
  children: React.ReactNode[] | React.ReactNode;
  items: string[];
  className?: string;
  onDrop: (items: string[]) => void;
  id?: string;
}

export const DragAndDropZone = ({
  children,
  className,
  items,
  onDrop,
  id = "zone",
}: DragAndDropZoneProps) => {
  const ref = useRef(null);

  const reorderItems = useCallback(
    (startIndex: number, finishIndex: number) => {
      const updatedItems = reorder({
        list: items,
        startIndex,
        finishIndex,
      });
      onDrop(updatedItems);
    },
    [items, onDrop],
  );

  const handleDrop = useCallback(
    ({ source, location }: BaseEventPayload<ElementDragType>) => {
      const dropTarget = location.current.dropTargets[0];
      if (!dropTarget) {
        return;
      }

      const startIndex = items.indexOf(source.data.id as string);
      const targetId = dropTarget.data.id as string;
      const indexOfTarget = items.indexOf(targetId);

      const destinationIndex = getReorderDestinationIndex({
        startIndex,
        indexOfTarget,
        closestEdgeOfTarget: extractClosestEdge(dropTarget.data),
        axis: "vertical",
      });

      reorderItems(startIndex, destinationIndex);
    },
    [items, reorderItems],
  );

  useEffect(() => {
    const dropZone = ref.current;
    invariant(dropZone);

    return dropTargetForElements({
      element: dropZone,
      getData: () => ({ type: "zone", id }),
      getIsSticky: () => true,
    });
  }, []);

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop,
    });
  }, [handleDrop]);

  return (
    <div ref={ref} className={cn("flex flex-col", className)}>
      <LayoutGroup>{children}</LayoutGroup>
    </div>
  );
};

export type DraggableState =
  | { type: "idle" }
  | { type: "preview"; container: HTMLElement }
  | { type: "dragging" };

export interface DragAndDropItemProps {
  children: React.ReactNode;
  className?: string;
  id: string;
  dragPreview?: React.ReactNode;
}

export const DragAndDropItem = ({
  children,
  className,
  id,
  dragPreview,
}: DragAndDropItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [closestEdge, setClosestEdge] = useState<
    "top" | "bottom" | "right" | "left" | null
  >(null);
  const [dragstate, setDragstate] = useState<DraggableState>({ type: "idle" });

  useEffect(() => {
    const dragItem = ref.current;
    const handle = handleRef.current;
    invariant(dragItem);
    invariant(handle);

    return combine(
      draggable({
        element: dragItem,
        getInitialData: () => ({ id }),
        dragHandle: handle,
        onGenerateDragPreview({ nativeSetDragImage }) {
          if (dragPreview) {
            setCustomNativeDragPreview({
              getOffset: pointerOutsideOfPreview({
                x: "5px",
                y: "5px",
              }),
              nativeSetDragImage,
              render({ container }) {
                setDragstate({ type: "preview", container });
                return () => setDragstate({ type: "dragging" });
              },
            });
          }
        },
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element: dragItem,
        getData: ({ input, element }) => {
          const data = { type: "item", id };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ["top", "bottom"],
          });
        },
        getIsSticky: () => true,
        onDragEnter: (args) => {
          if (args.source.data.id !== id) {
            setClosestEdge(extractClosestEdge(args.self.data));
          }
        },
        onDrag: (args) => {
          if (args.source.data.id !== id) {
            setClosestEdge(extractClosestEdge(args.self.data));
          }
        },
        onDragLeave: () => {
          setClosestEdge(null);
        },
        onDrop: () => {
          setClosestEdge(null);
        },
      }),
    );
  }, [id]);

  return (
    <>
      {dragstate.type === "preview"
        ? createPortal(dragPreview, dragstate.container)
        : null}
      <motion.div
        layout
        transition={{ duration: 0.2 }}
        ref={ref}
        className={cn(
          "relative py-2 flex gap-1 items-center -ml-1",
          className,
          isDragging && "opacity-50",
        )}
      >
        <div ref={handleRef} className={cn("text-faint cursor-grab")}>
          <Icon type="dragVertical" size="default" />
        </div>
        {children}
        {closestEdge && <DropIndicator edge={closestEdge} />}
      </motion.div>
    </>
  );
};

export const DropIndicator = ({
  edge,
}: {
  edge: "top" | "bottom" | "left" | "right";
}) => {
  return (
    <div
      className={cn(
        "absolute inset-x-0 h-px border-b border-b-primary z-10",
        edge === "top" && "top-0",
        edge === "bottom" && "bottom-0",
      )}
    >
      <div className="absolute w-2 h-2 border border-primary rounded-pill bg -left-1 -translate-y-1/2" />
    </div>
  );
};
