import { Icon, Text } from "capsa-ui";

export const ChatFailed = () => {
  return (
    <div
      data-last-reply={true}
      className="flex gap-2.5 p-2 animate-in fade-in-0 slide-in-from-bottom-1 duration-500 items-center"
    >
      <div className="w-5 h-5 min-w-5 min-h-5 flex-center">
        <Icon type="alertFilled" className="text-danger" />
      </div>
      <Text className="text-danger">
        Something went wrong. Please try again.
      </Text>
    </div>
  );
};
