import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Button, Divider, Heading, Text, TextField } from "capsa-ui";
import {
  useBrandingConfigQuery,
  useUpdateBrandingConfigMutation,
} from "@/features/branding/hooks";
import { useValidatedParams } from "@/utils/router";
import { useEffect, useState } from "react";

export const SettingsView = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { data } = useBrandingConfigQuery(orgId!);
  const updateBrandingConfig = useUpdateBrandingConfigMutation();

  const [tempAccentColor, setTempAccentColor] = useState<string>("#000000");

  useEffect(() => {
    setTempAccentColor(data?.accentColor || "#000000");
  }, [data]);

  const handleAccentColorChange = () => {
    updateBrandingConfig.mutate({
      orgId: orgId!,
      body: {
        logo: data?.logoBase64 || undefined,
        logoFilename: data?.logoFilename || undefined,
        accentColor: tempAccentColor,
      },
    });
  };

  const handleCompanyLogoChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      let base64Logo = "";
      const reader = new FileReader();
      base64Logo = await new Promise((resolve) => {
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(file);
      });

      updateBrandingConfig.mutate({
        orgId: orgId!,
        body: {
          logo: base64Logo,
          logoFilename: file.name,
          accentColor: data?.accentColor || undefined,
        },
      });
    }
  };

  const handleClearCompanyLogo = () => {
    updateBrandingConfig.mutate({
      orgId: orgId!,
      body: {
        logo: undefined,
        logoFilename: null,
        accentColor: data?.accentColor || undefined,
      },
    });
  };

  const handleResetToDefaults = () => {
    updateBrandingConfig.mutate({
      orgId: orgId!,
      body: {
        logo: undefined,
        logoFilename: null,
        accentColor: "#000000",
      },
    });
  };

  return (
    <DashboardLayout title="Settings">
      <div className="flex flex-col gap-4 py-2 items-start flex-1">
        <div className="flex flex-col gap-3 w-full">
          <div>
            <Heading type="h2">Customisation</Heading>
            <Text type="label" color="subtle">
              Change the look and feel of your exported dashboards.
            </Text>
          </div>
          <Divider />
          {data?.logoBase64 ? (
            <div className="flex flex-col gap-1.5">
              <Text type="label" color="subtle" weight="bold">
                Company Logo
              </Text>
              <div className="flex items-center gap-2">
                <img
                  src={data.logoBase64}
                  alt="Company Logo"
                  className="h-10 w-auto object-contain"
                />
                <Button
                  iconLeft="cancel"
                  size="sm"
                  variant="outline"
                  onClick={handleClearCompanyLogo}
                >
                  Clear
                </Button>
              </div>
            </div>
          ) : (
            <TextField
              label={"Company Logo"}
              onChange={handleCompanyLogoChange}
              description="png, jpg"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
            />
          )}
        </div>
        <TextField
          label="Brand Accent Color"
          value={tempAccentColor}
          onChange={(e) => setTempAccentColor(e.target.value)}
          onBlur={handleAccentColorChange}
          type="color"
          className="w-fit"
        />
        <Divider />
        <Button variant="outline" onClick={handleResetToDefaults}>
          Reset to defaults
        </Button>
      </div>
    </DashboardLayout>
  );
};
