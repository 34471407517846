import {
  Button,
  ResizablePanel,
  ResizableHandle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  SelectTrigger,
  SelectValue,
  Select,
  SelectContent,
  SelectItem,
  Text,
} from "capsa-ui";
import { useDashboardStore } from "../../store/useDashboardStore";
import { useMemo, useRef } from "react";
import { ImperativePanelHandle } from "react-resizable-panels";
import { Assistant } from "@/features/assistant/components/Assistant";
import { Widget } from "@/types/widgets";
import { SourceNode } from "@/features/assistant/types/api";

type Props = {
  widgets: Widget[];
};

export const ContextWindow = ({ widgets }: Props) => {
  const panelRef = useRef<ImperativePanelHandle>(null);
  const {
    closeContextWindow,
    contextWindowState,
    source,
    resetFocusedWidget,
    setFocusedWidget,
    focusedWidget,
    setSource,
  } = useDashboardStore();

  const handleClose = () => {
    closeContextWindow();
    resetFocusedWidget();
  };

  const handleSourceClick = (source: SourceNode) => {
    switch (source.source_info.type) {
      case "webpage":
        setSource({
          citationNumber: 0,
          searchResult: {
            type: source.source_info.type,
            id: source.id,
            websiteId: source.source_info.website_id,
            webpageId: source.source_info.webpage_id,
            url: source.source_info.url,
          },
        });
        break;

      case "file":
      case "file_table":
        setSource({
          citationNumber: 0,
          searchResult: {
            type: "file",
            id: source.id,
            fileId: source.source_info.file_id,
            fileName: source.source_info.name,
            pageNumber: source.source_info.page_number,
          },
        });
        break;

      case "external_webpage":
        window.open(source.source_info.url, "_blank");
        break;
    }
  };

  const assistantWidget = useMemo(() => {
    return widgets.find((w) => w.id === focusedWidget);
  }, [focusedWidget, widgets]);

  return (
    <>
      <ResizableHandle disabled={!contextWindowState && !source} />
      <ResizablePanel
        ref={panelRef}
        minSize={20}
        className="bg-secondary"
        id="context-window"
        order={1}
      >
        <div className="flex flex-1 flex-col h-full">
          <div className="flex h-11 items-center border-b p-1 pl-3">
            <div className="flex items-center gap-1.5">
              <Text type="label" className="text-subtle">
                Context:
              </Text>
            </div>
            <Select value={focusedWidget} onValueChange={setFocusedWidget}>
              <SelectTrigger
                className="w-fit [&>span]:text [&>span]:font-medium"
                variant="ghost"
                size="sm"
              >
                <SelectValue placeholder="Select a widget" />
              </SelectTrigger>
              <SelectContent>
                {widgets.map((w) => (
                  <SelectItem key={w.id} value={w.id}>
                    {w.prettyName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="flex-1" />
            <Tooltip>
              <TooltipTrigger>
                <Button
                  iconLeft="cancel"
                  variant="ghost"
                  onClick={handleClose}
                />
              </TooltipTrigger>
              <TooltipContent side="left">Close</TooltipContent>
            </Tooltip>
          </div>
          {assistantWidget && (
            <Assistant
              widget={assistantWidget}
              onSourceClick={handleSourceClick}
            />
          )}
        </div>
      </ResizablePanel>
    </>
  );
};
