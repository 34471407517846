import { Command as CommandPrimitive } from "cmdk";
import { useMemo, useState } from "react";
import { TextField, TextFieldProps } from "../TextField";
import { Spinner } from "../Spinner";
import { Popover, PopoverContent } from "../Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../Command";
import { PopoverAnchor } from "@radix-ui/react-popover";
import { Text } from "../Text";

export type AutocompleteProps<T extends string> = {
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  onSelect: (value: T) => void;
  items: { value: T; label: string; description?: string }[];
  isLoading?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  loadingText?: string;
};

export function Autocomplete<T extends string>({
  searchValue,
  onSearchValueChange,
  onSelect,
  items,
  isLoading,
  emptyMessage = "No items.",
  placeholder = "Search...",
  textFieldProps,
  loadingText = "Searching...",
}: AutocompleteProps<T>) {
  const [open, setOpen] = useState(false);

  const labels = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          acc[item.value] = item.label;
          return acc;
        },
        {} as Record<string, string>,
      ),
    [items],
  );

  const onSelectItem = (inputValue: string) => {
    onSelect(inputValue as T);
    onSearchValueChange(labels[inputValue] ?? "");
    setOpen(false);
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <div className="flex items-center">
      <Popover open={open && !!searchValue} onOpenChange={setOpen}>
        <Command shouldFilter={false} className="overflow-visible">
          <PopoverAnchor asChild>
            <CommandPrimitive.Input
              asChild
              value={searchValue}
              onValueChange={onSearchValueChange}
              onKeyDown={(e) => setOpen(e.key !== "Escape")}
              onMouseDown={() => setOpen((open) => !!searchValue || !open)}
              onFocus={() => setOpen(true)}
            >
              <TextField
                placeholder={placeholder}
                type="search"
                {...textFieldProps}
              />
            </CommandPrimitive.Input>
          </PopoverAnchor>
          {!open && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            onOpenAutoFocus={(e) => e.preventDefault()}
            onInteractOutside={(e) => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className="w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              {isLoading && (
                <CommandPrimitive.Loading>
                  <div className="p-3 flex items-center gap-2">
                    <Spinner size="sm" />
                    <Text type="label">{loadingText}</Text>
                  </div>
                </CommandPrimitive.Loading>
              )}
              {items.length > 0 && !isLoading ? (
                <CommandGroup>
                  {items.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onMouseDown={(e) => e.preventDefault()}
                      onSelect={onSelectItem}
                      className="flex flex-col gap-0"
                    >
                      {option.label}
                      {option.description && (
                        <Text type="label" color="faint">
                          {option.description}
                        </Text>
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ) : null}
              {!isLoading ? (
                <CommandEmpty>{emptyMessage ?? "No items."}</CommandEmpty>
              ) : null}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  );
}
