import type React from "react";
import styles from "./Text.styles";

export type TextType = "subheading" | "body" | "label" | "caption";
export type TextColor = "default" | "subtle" | "faint" | "primary" | "danger";
export type TextStyle = "default" | "underline";
export type TextWeight = "default" | "medium" | "bold";

export interface TextProps {
  children: React.ReactNode;
  type?: TextType;
  truncated?: boolean;
  weight?: TextWeight;
  color?: TextColor;
  style?: TextStyle;
  className?: string;
}

export const Text = ({
  children,
  type = "body",
  truncated,
  weight = "default",
  color = "default",
  style = "default",
  className = "",
}: TextProps) => {
  return (
    <span
      className={`${styles({ type, truncated, weight, color, style })} ${className}`.trim()}
    >
      {children}
    </span>
  );
};
