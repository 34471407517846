import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationConfig } from "@/lib/reactQuery";
import { Thread } from "../types/api";

export const createThread = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
): Promise<Thread> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}/assistant-threads`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};

type useCreateThreadOptions = {
  orgId: string;
  dealId: string;
  widgetId: string;
  config?: MutationConfig<typeof createThread>;
};

export const useCreateThread = ({
  orgId,
  dealId,
  widgetId,
  config,
}: useCreateThreadOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => createThread(user, orgId, dealId, widgetId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "widgets",
          widgetId,
          "assistant-threads",
        ],
      });
    },
    ...config,
  });
};
