import { Slot } from "@radix-ui/react-slot";
import React from "react";
import { cn } from "../../../../lib/utils";

export const SidebarGroupLabel = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & { asChild?: boolean }
>(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "div";

  return (
    <Comp
      ref={ref}
      data-sidebar="group-label"
      className={cn(
        "duration-200 text-faint flex h-8 shrink-0 items-center rounded-md px-2 text-caption font-bold ease-in-out focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0 pointer-events-none",
        "group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0",
        className,
      )}
      {...props}
    />
  );
});
SidebarGroupLabel.displayName = "SidebarGroupLabel";
