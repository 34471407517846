import { User } from "../../../types";
import { baseURL, mapToSnakeCase, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationConfig } from "@/lib/reactQuery";
import {
  LocalContentGenerationParams,
  Person,
  TimeSeries,
  Topic,
  Event,
  Widget,
} from "@/types/widgets";

export const updateWidget = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
  body: {
    content?: TimeSeries[] | Person[] | Event[] | Topic[];
    localContentGenerationParams?: LocalContentGenerationParams;
  },
): Promise<Widget> => {
  const snake_case_body = mapToSnakeCase(body, { blacklist: ["searchResult"] });
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(snake_case_body),
    }),
    true,
  );
};

type useUpdateWidgetOptions = {
  orgId: string;
  dealId: string;
  widgetId: string;
  config?: MutationConfig<typeof updateWidget>;
};

export const useUpdateWidget = ({
  orgId,
  dealId,
  widgetId,
  config,
}: useUpdateWidgetOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      content?: TimeSeries[] | Person[] | Event[] | Topic[];
      localContentGenerationParams?: LocalContentGenerationParams;
    }) => updateWidget(user, orgId, dealId, widgetId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "widgets", widgetId],
      });
    },
    ...config,
  });
};
