import {
  useUpdateUserInviteMutation,
  useUserInvitesQuery,
  useUserOrgPermissionsQuery,
} from "../hooks";
import { useNavigate } from "react-router";
import info from "../info";
import { paths } from "@/config/paths";
import { Button, CapsaLogo, Divider, Icon, Spinner, Text } from "capsa-ui";

export default function SingleTenantOrgInvite() {
  return (
    <div className="flex flex-col gap-6 max-w-[400px] mx-auto bg rounded-layout border p-10">
      <div className="flex gap-4 justify-between items-center">
        <CapsaLogo />
        <Text className="text-center" type="subheading" weight="medium">
          {info.orgId}
        </Text>
      </div>
      <Divider />
      <div className="flex flex-col gap-4">
        <Text className="text-center">
          To enter, you must accept the invite.
        </Text>
        <OrgInvite />
      </div>
    </div>
  );
}

export function OrgInvite() {
  const userInvitesQuery = useUserInvitesQuery();
  const userOrgPermission = useUserOrgPermissionsQuery(info.orgId);
  const updateUserInviteMutation = useUpdateUserInviteMutation();
  const navigate = useNavigate();

  if (userOrgPermission.data !== undefined && userOrgPermission.data.size > 0) {
    navigate(paths.root.getHref());
  }

  // Loading
  if (userInvitesQuery.isLoading) {
    return (
      <div className="flex-center gap-2">
        <Spinner size="sm" />
        <Text className="text-center">Loading invites.</Text>
      </div>
    );
  }

  if (userInvitesQuery.isError || userInvitesQuery.data.length === 0) {
    return (
      <div className="flex gap-2 p-2 bg-warning rounded-layout">
        <Icon type="info" size="sm" className="text-warning mt-0.5" />
        <Text>
          {
            "Sorry, you don't have an invite. Contact your Capsa AI representative."
          }
        </Text>
      </div>
    );
  }

  const invite = userInvitesQuery.data[0];

  return (
    <Button
      onClick={() =>
        updateUserInviteMutation.mutate({
          inviteId: invite.id,
          orgId: invite.org_id,
          data: {
            response: "accept",
          },
        })
      }
      type="button"
      size="lg"
      fullWidth
      loading={updateUserInviteMutation.isLoading}
    >
      Accept
    </Button>
  );
}
