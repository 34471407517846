import { Avatar, Heading, ProgressBar, Spinner, Text, cn } from "capsa-ui";
import { ReactNode } from "react";

interface Props {
  avatar?: string;
  children?: ReactNode | ReactNode[];
  actions?: ReactNode[];
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  refreshing?: boolean;
  loading?: boolean;
}

export const DashboardLayout = ({
  avatar,
  children,
  actions,
  title,
  description,
  className,
  refreshing,
  loading,
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-1 flex-col gap-3 p-4 pt-1 bg min-h-0",
        className,
      )}
    >
      <div className="h-14 w-full shrink-0" />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {(actions || title || description) && (
            <header
              className={cn(
                "flex items-end border-b pb-3 gap-20 relative",
                refreshing && "border-b-transparent",
              )}
            >
              <div className="flex items-start gap-2.5 flex-1 h-full">
                {avatar && (
                  <div className="flex-center p-1.5 rounded-layout border shrink-0">
                    <Avatar src={avatar} size="hero" />
                  </div>
                )}
                <div className="flex flex-col gap-0.5 flex-1 min-h-full justify-center">
                  {title && <Heading>{title}</Heading>}
                  {description && (
                    <div>
                      <Text
                        type="label"
                        color="subtle"
                        className="line-clamp-3"
                      >
                        {description}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex gap-1 flex-wrap max-w-[50%] justify-end">
                {actions}
              </div>
              {refreshing && (
                <ProgressBar
                  value={null}
                  className="absolute bottom-[-1px] left-0 right-0 h-0.5"
                />
              )}
            </header>
          )}
          {children}
        </>
      )}
    </div>
  );
};
